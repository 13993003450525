import React from "react";
import { StyledHeader } from "./header.styles";
import Logo from "../svg/logo.svg";
import NavButton from "../svg/nav-button.svg";
import { LinkPart } from "../parts/link";
import { trackEvent } from "../helpers/analytics";
import { useHeaderContext } from "../global/contexts/header.context";
import { useModalContext } from "../global/contexts/modal.context";
import { useNavContext } from "../global/contexts/navigation.context";

const Header: React.FC = () =>  {
  const { scrolledUp } = useHeaderContext();
  const { setModalOpen, modalId, isModalOpen } = useModalContext();
  const { toggleNavState, isNavOpen } = useNavContext();

  const handleClick = React.useCallback(
    () => {
      if (!!toggleNavState && !!setModalOpen) {
        if (!isModalOpen) {
          toggleNavState(!!isNavOpen);
          trackEvent('toggle_nav', {
            event_category: 'navigation',
            event_label: isNavOpen ? 'Close Navigation' : 'Open Navigation',
            value: isNavOpen ? 'Close' : 'Open'
          });
        } else {
          setModalOpen(false);
          trackEvent('close_modal', {
            event_category: 'modal',
            event_label: `Open ${modalId} Modal`,
            value: modalId
          });
        }
      }
    },
    [isModalOpen, isNavOpen, toggleNavState, setModalOpen]
);

  return (
    <StyledHeader scrolledUp={scrolledUp}>
      <div className="header__logo">
        <LinkPart to="/" >
          <Logo />
        </LinkPart>
      </div>
      <div className='header__ham-container'>
        <div className={`header__ham ${isNavOpen || isModalOpen ? 'header__ham--close' : 'header__ham--open'}`}>
          <a onClick={handleClick}>
            <NavButton />
          </a>
        </div>
      </div>
    </StyledHeader>
  );
}

export default Header;
