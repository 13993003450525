import styled from 'styled-components';
import { LinkStyles } from '../global/typography.styles';

export interface IStyledLinkProps {
    cmsContent?: boolean;
}

export const StyledLink = styled.span<IStyledLinkProps>`
    ${LinkStyles}
`;
