import React from "react"
import { Field, useField } from "formik"
import { IBaseFieldProps } from "./base-field"
import { validateDateField } from "../../helpers/input"
import { FieldWrapper } from "./field-wrapper"
import { StyledDateField } from "./date-field.styles"
import { ThemeProvider } from "styled-components"
import { siteTheme } from "../../global/theme.styles"
import { formatDate } from "../../helpers/dates"
import { SwitchTransition, CSSTransition } from "react-transition-group"

export interface IDateFieldProps extends IBaseFieldProps {}

export const DateField: React.FC<IDateFieldProps> = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const dateFieldValidator = React.useCallback(
    (value: string) => {
      return validateDateField(value, props.required)
    },
    [props.required]
  );

  const [field, meta] = useField({
    name: props.name,
    validate: dateFieldValidator,
  });

  return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
      <StyledDateField
        touched={meta.touched}
        filled={!!meta.value}
        error={!!meta.error}
      >
        <FieldWrapper
          {...props}
          label={label}
          touched={meta.touched}
          filled={!!meta.value}
          error={meta.error}
        >
          <Field
            {...props}
            className="date-input"
            validate={dateFieldValidator}
            type="date"
          />
        </FieldWrapper>
      </StyledDateField>
    </ThemeProvider>
  )
}
