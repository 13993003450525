import styled from 'styled-components';
import { media } from '../global/media.styles';

export const StyledVideoModal = styled.div`
    position: relative;
    transform: translate3d(0, 0, 0);

    .video-modal__video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        transform: translate3d(0, 0, 0);
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        transform: translate3d(0, 0, 0);
    }
`;
