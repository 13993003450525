import * as React from 'react';
import { useEffect } from 'react';
import { leftPosition, windowWidth } from '../helpers/positions';
import { smoothScrollToX } from '../helpers/scroll';
import { StyledCarousel } from './carousel.styles';
import { StyledCarouselTrack } from './carousel/carousel-track.styles';
import { CarouselButtonDirection, CarouselNavigationButton } from './carousel/navigation-button';
import { CarouselSlide } from './carousel/slide';
import { StyledSlideCount } from './carousel/slide-count.styles';

export interface ICarouselProps {
    initialSlide?: number;
    slides?: JSX.Element[];
    inModal?: boolean;
    hasSlideCount?: boolean;
    hasButtons?: boolean;
}

export const getSlideIdFromElement = (element: Element) => parseInt(element.id.replace('slide--', ''));

export const Carousel: React.FC<ICarouselProps> = props => {
    const trackRef = React.useRef<HTMLDivElement>(null);
    // This is for what we see
    const [slideInView, setSlideInView] = React.useState<number>(0);
    // Changed whenever we want to go to a slide
    const [nextSlide, setNextSlide] = React.useState<number | undefined>(props.initialSlide ||undefined);
    // Props for buttons
    const [maybeNextSlide, setMaybeNextSlide] = React.useState<number | undefined>();
    const [maybePreviousSlide, setMaybePrevioiusSlide] = React.useState<number | undefined>();
    // The all important listener
    const [slideObserver, setSlideObserver] = React.useState<IntersectionObserver | undefined>();

    React.useEffect(() => {

        if ( !slideObserver && !!trackRef.current) {
            const observerOptions = {
                root: trackRef.current,
                rootMargin: '0px',
                threshold: 0.8
            };
    
            setSlideObserver(new IntersectionObserver(updateSlideInView, observerOptions));
        }

        return () => {
            !!slideObserver && slideObserver.disconnect();
        };
    },
    [slideObserver, trackRef]
    );

    useEffect(() => {
        const newMaybeNextSlide = slideInView + 1;
        setMaybeNextSlide(newMaybeNextSlide < (!!props.slides && props.slides?.length) || 0 ? newMaybeNextSlide : undefined);

        const newMaybePreviousSlide = slideInView - 1;
        setMaybePrevioiusSlide(newMaybePreviousSlide > -1 ? newMaybePreviousSlide : undefined);
    }, [slideInView, props.slides?.length, setMaybeNextSlide, setMaybePrevioiusSlide]);

    const updateSlideInView = React.useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {

            entries.forEach((entry: IntersectionObserverEntry) => {
                if ( entry.isIntersecting && entry.intersectionRatio > 0.7 ) {
                    const currentSlideNode = entry.target;
                    const slideIndex = getSlideIdFromElement(currentSlideNode);
                    setSlideInView(slideIndex);
                }
            });
        },
        [setSlideInView]
    );

    const updateNextSlide = React.useCallback(
        (newNextSlide: number) => {
            nextSlide !== newNextSlide && newNextSlide !== slideInView && setNextSlide(newNextSlide);
        },
        [nextSlide, slideInView, setNextSlide]
    );

    const buttonClickHandler = React.useCallback(
        (direction: CarouselButtonDirection) => {
            const newNextSlide = direction === 'next' ? maybeNextSlide : maybePreviousSlide;

            newNextSlide !== undefined && updateNextSlide(newNextSlide);
        },
        [updateNextSlide, maybeNextSlide, maybePreviousSlide]
    );
 
    const scrollToSlide = React.useCallback(
        (newCurrentSlide: HTMLDivElement, nextSlideIndex: number) => {
            nextSlide !== undefined && setNextSlide(undefined);

            if (!!trackRef.current) {
                const currentWindowWidth = windowWidth();
                const isTabletLandscape = currentWindowWidth > 1024;

                const animationTime = 500 * Math.max(Math.abs(slideInView - nextSlideIndex) * 0.8, 1);
                const offset = isTabletLandscape ? currentWindowWidth * 0.2 : 0;
                
                let scrollTo = 0;

                if (nextSlideIndex !== 0) {
                    const adjustedOffset = (nextSlideIndex === props.slides?.length) ? offset * 2 : offset;
                    scrollTo = leftPosition(newCurrentSlide, trackRef.current) - adjustedOffset;
                }

                smoothScrollToX(scrollTo, trackRef.current, animationTime);
            }
        },
        [trackRef, nextSlide, setNextSlide, slideInView, props.slides]
    );

    return (
        <StyledCarousel inModal={props.inModal}>
            <StyledCarouselTrack slideCount={props.slides?.length} inModal={props.inModal} ref={trackRef}>
                {props.slides?.map((Slide, index) => {
                    return (
                        <CarouselSlide 
                            slideId={index}
                            isNextSlide={index === nextSlide}
                            scrollToSlide={scrollToSlide}
                            inModal={props.inModal}
                            observer={slideObserver}
                            key={index}
                        >
                            {Slide}
                        </CarouselSlide>
                    )
                })}
            </StyledCarouselTrack>
            {props.hasButtons &&
                <>
                <CarouselNavigationButton
                    direction="previous"
                    disabled={maybePreviousSlide === undefined}
                    buttonClickHandler={buttonClickHandler}
                    inModal={props.inModal}
                >
                    Previous
                </CarouselNavigationButton>
                <CarouselNavigationButton
                    direction="next"
                    disabled={maybeNextSlide === undefined}
                    buttonClickHandler={buttonClickHandler}
                    inModal={props.inModal}
                    >
                    Next
                </CarouselNavigationButton>
                </>
            }
            {props.hasSlideCount &&
                <StyledSlideCount>
                    {`${slideInView !== undefined ? slideInView + 1 : 0}/${props.slides?.length}`}
                </StyledSlideCount>
            }
        </StyledCarousel>
  );
};
