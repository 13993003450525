import styled from 'styled-components';
import {
  baseEasingFunction,
  transitionTiming,
} from '../global/animation.styles';

export const pageTransition = `
  transition: opacity ${transitionTiming('base')} ${baseEasingFunction};
`;

export const StyledPageTransition = styled.div`
  ${pageTransition}
  width: 100%;
  height: 100%;

  &.exit-active,
  &.exit-done {
    opacity: 0;
    pointer-events: none;
  }

  &.appear,
  &.appear-active,
  &.enter {
    opacity: 0;
  }

  &.enter-active,
  &.enter-done {
    opacity: 1;
  }

  &.enter-done {
    pointer-events: all;
  }
`;
