import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { validateTextField } from "../../helpers/input";
import { FieldWrapper } from "./field-wrapper";
import { StyledTextField } from "./text-field.styles";

export type ValidationCallback<TValue> = (
  value: TValue
) => string | void | undefined;

export interface ITextFieldProps extends IBaseFieldProps {
  validator?: ValidationCallback<string>;
  hidden?: boolean;
}

export const TextField: React.FC<ITextFieldProps> = ({ label, validator, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const textFieldValidator = React.useCallback((value: string) => {
    return validateTextField(value, props.required);
  }, [props.required]);

  const [field, meta] = useField({ name: props.name, validate: validator || textFieldValidator });

  return (
    <StyledTextField>
      <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error}>
        <Field {...props}
          className="text-input"
          validate={validator || textFieldValidator}
        />
      </FieldWrapper>
    </StyledTextField>
  );
};