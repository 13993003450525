import { useStaticQuery, graphql } from "gatsby";
import { PostsQueryQuery, SocialMenuQueryQuery } from "../../gatsby-graphql";

export const useSocialLinks = () => {
  const data = useStaticQuery<SocialMenuQueryQuery>(graphql`
  query SocialMenuQuery {
    allWpMenuItem(filter: { locations: { eq: SOCIAL} } ) {
      nodes {
        ...MenuItemsFragment
      }
    }
  }`
  )
  return data.allWpMenuItem
}