import styled from 'styled-components';
import { media } from '../global/media.styles';
import { NoScrollBarStyle } from '../global/placeholders';

export interface IStyledCarouselProps {
    slideCount?: number;
    inModal?: boolean;
}

export const StyledCarousel = styled.div<IStyledCarouselProps>`
    position: relative;
    width: 100%;
    height: ${p =>  p.inModal ? '100%' : 'auto'};
`;
