import * as React from 'react';
import { StyledCarouselNavigationButton } from './navigation-button.styles';
import ArrowIcon from '../../svg/arrow.svg';

export type CarouselButtonDirection = 'previous' | 'next';

export interface ICarouselButtonProps {
    direction: CarouselButtonDirection;
    disabled?: boolean;
    buttonClickHandler?: (direction: CarouselButtonDirection) => void;
    inModal?: boolean;
}

export const CarouselNavigationButton: React.FC<ICarouselButtonProps> = props => {

    return (
        <StyledCarouselNavigationButton onClick={() => props.buttonClickHandler && props.buttonClickHandler(props.direction)} direction={props.direction} inModal={props.inModal} disabled={props.disabled}>
            <div className="arrow-icon__container">
                <ArrowIcon />
            </div>
            {props.children}
        </StyledCarouselNavigationButton>
  );
};
