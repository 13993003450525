import styled from 'styled-components';
import { colors, themedBackground, themedColor } from '../global/theme.styles';
import { paragraphStyles, smallParagraphStyles, FooterLabelTextStyles, FooterLinkStyles } from '../global/typography.styles';
import { media } from '../global/media.styles';
import { spacing } from '../global/spacing.styles';

export const StyledFooter = styled.footer`
    ${themedBackground};
    ${themedColor};
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: ${spacing.footerPaddingY}px 0;

    & a {
        ${paragraphStyles}
        ${FooterLinkStyles}
    }

    & .footer {
        &__info {
            ${smallParagraphStyles}
            display: inline-block;
            width: 100%;
            grid-column: 1/3;
            padding-top: ${spacing.footerPaddingY}px;
            margin-bottom: 0;
            text-align: center;

            @media ${media.tablet} {
                grid-column: 2/3;
                padding-top: 0;
                padding-right:${spacing.footerPaddingX}px;
                text-align: right;
            }

            span {
                display: block;
            }

            a {
                ${FooterLinkStyles}
            }
        }

        &__label {
            ${FooterLabelTextStyles}
        }

        &__sub-navigation {
            ${themedColor};
            padding-top: ${spacing.footerPaddingY}px;
            text-align: center;
            grid-column: 1/3;

            a {
                ${smallParagraphStyles}
                ${FooterLinkStyles}
            }
        }

        &__copyright {
            ${themedColor};
            ${smallParagraphStyles}
            grid-column: 1/3;
            text-align: center;

            &::first-letter {
                line-height: 16px;
                vertical-align: middle;
            }
        }
    }
`;
