import React from "react";
import { Form } from 'formik';
import { StyledButton } from "../parts/button.styles";
import { TextField } from "./fields/text-field";
import { PhoneField } from "./fields/phone-field";
import { EmailField } from "./fields/email-field";
import { TextAreaField } from "./fields/text-area-field";
import FormWrapper from './form';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { getSubmitTextFromState, useFormContext } from "./form.context";
import { useSettings } from "../hooks/settings.hook";

export interface IContactFormProps {
}

export interface IContactFormState {
  fullName?: string,
  email?: string,
  phone?: string,
  message?: string,
  sendTo?: string,
  ccTo?: string,
}

const validate = (values: IContactFormState) => {
  const errors: IContactFormState = {};

  console.log('Form Specific Validation');

  return errors;
};

export const formName = 'contact';

const ContactForm: React.FC<IContactFormProps> = props => {
  const { formState } = useFormContext();
  const settings = useSettings();

  const initialValues: IContactFormState = {
    fullName: '',
    email: '',
    phone: '',
    message: '',
    sendTo: settings?.safetyFirstContactEmailTo || '',
    ccTo: settings?.safetyFirstContactEmailCc || '',
  };

  return (
    <FormWrapper
      initialValues={initialValues}
      formName={formName}
      successMessage={'Thank you for contacting us! We will be in touch soon.'}
    >
      <Form>
        <TextField
          label="Full Name"
          name="fullName"
          placeholder="Full Name"
          required={true}
        />
        <EmailField
          label="Email"
          name="email"
          placeholder="Email"
          required={true}
        />
        <PhoneField
          label="Phone"
          name="phone"
          placeholder="Phone Number"
        />
        <TextAreaField
          label="Message"
          name="message"
          placeholder="Your Message..."
          required={true}
        />
        <StyledButton type="submit" disabled={formState !== 'Initial'}>
          <SwitchTransition>
            <CSSTransition key={formState}
              classNames={'submit-value'}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
            >
              <div className="form-button__submit-value">{getSubmitTextFromState(formState || 'Initial')}</div>
            </CSSTransition>
          </SwitchTransition>
        </StyledButton>
      </Form>
    </FormWrapper>
  )
}

export default ContactForm;
