import React from "react";
import { StyledSocialNavigation } from "./social.styles";
import FacebookIcon from "../svg/facebook-icon.svg";
import LinkedInIcon from "../svg/linkedin-icon.svg";
import GoogleIcon from "../svg/google-icon.svg";
import { LinkPart } from "../parts/link";
import { useSocialLinks } from "../hooks/social-links.hook";

const SocialNavigation = () => {
  const menuItems = useSocialLinks();

  return (
    <StyledSocialNavigation>
      <ul className="social-navigation">
        {menuItems?.nodes && menuItems.nodes.map(item => {
          const url= item?.connectedNode && item.connectedNode.node?.slug ? item.connectedNode.node.slug : item?.url;
          let icon: any | undefined = undefined;
          switch(item?.label) {
            case "Facebook":
                icon = <FacebookIcon />
                break;
            case "LinkedIn":
                icon = <LinkedInIcon />
                break;
            case "Google":
                icon = <GoogleIcon />
                break;
          }

          return (
            <li className="social-navigation__item" key={item?.id}>
              <LinkPart className="social-navigation__link" to={url || ''}>
                {icon}
              </LinkPart>
            </li>
          );
        })}
      </ul>
    </StyledSocialNavigation>
  );
}

export default SocialNavigation;
