import React from 'react';

export type IObserverState = Partial<ReturnType<typeof useObserverState>>;

export const ObserverContext = React.createContext<IObserverState | null>(
null
);

export const useObserverState = () => {
    const [inViewObserver, setInViewObserver] = React.useState<IntersectionObserver | undefined>();

    React.useEffect(() => {
        if ( !inViewObserver) {
            const observerOptions = {
                rootMargin: '-90px 0px -10% 0px',
                thresholds: [0]
            };
    
            setInViewObserver(new IntersectionObserver(updateElementInView, observerOptions));
        }

        return () => {
            !!inViewObserver && inViewObserver.disconnect();
        };
    }, []);

    const updateElementInView = React.useCallback(
        (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {

            entries.forEach((entry: IntersectionObserverEntry) => {
                const inViewNode = entry.target;

                if ( entry.isIntersecting ) {
                    inViewNode.classList.add('in-view--visible');
                    observer.unobserve(inViewNode);
                }
            });
        },
        []
    );

    const registerElementInView = React.useCallback(
        (inViewElementRef: HTMLElement) => {
            if (!!inViewObserver && !!inViewElementRef) {
                inViewObserver.observe(inViewElementRef);
                inViewElementRef.classList.add('in-view');
            }
        },
        [inViewObserver]
    );

    const unregisterElementInView = React.useCallback(
        (inViewElementRef: HTMLElement) => {
            !!inViewObserver && !!inViewElementRef && inViewObserver.unobserve(inViewElementRef);
        },
        [inViewObserver]
    );

    return {
        inViewObserver,
        registerElementInView,
        unregisterElementInView,
    };
}

export const useObserverContext = () => {
    const observerContext = React.useContext(ObserverContext);

    if (!observerContext) {
        throw new Error(
        'ObserverMeta Context used outside of observerMetaContext.Provider'
        );
    }

    return observerContext;
};

export const ObserverContextProvider: React.FC = ({ children }) => {
    const observerState = useObserverState();

    return (
        <ObserverContext.Provider value={observerState}>
            {children}
        </ObserverContext.Provider>
    );
};
  