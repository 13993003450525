import React from "react";
import ApplyForm from "../forms/apply";
import { useModalContext } from "../global/contexts/modal.context";

const ApplyModal: React.FC = props => {
    const id = 'apply-modal';
    const { modalProps } = useModalContext();
    const initialPosition = modalProps && modalProps?.hashProps && modalProps.hashProps[0];

    return (
        <div>
            <ApplyForm initialPosition={initialPosition} />
        </div>
    );
}

export default ApplyModal;
