import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { h2Styles } from '../../global/typography.styles';

export const StyledSlideCount = styled.div`
    ${h2Styles}
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px 20px;

    @media ${media.desktop} {
        margin: 10px 20px;
    }
`;
