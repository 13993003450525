import React, { useCallback } from 'react';
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from 'react-transition-group';
import { useLocation } from '@reach/router';
import { StyledPageTransition } from './page-transition.styles';
import { hasWindow, hasDocument } from '../helpers/window';
import { scrollTop, topPosition, windowWidth } from '../helpers/positions';
import { smoothScrollToY } from '../helpers/scroll';
import { hashNavigate } from '../helpers/navigation';
import { useHeaderContext } from '../global/contexts/header.context';

export const PageTransition: React.FC = props => {
  const $html = React.useRef<HTMLElement | null>(null);
  const [pageMounted, setPageMounted] = React.useState(false);
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const { setHeaderVisible } = useHeaderContext();
  const location = useLocation();

  React.useEffect(() => {

    if (hasDocument) {
      $html.current = document.documentElement;
    }

    if (!!location.hash) {
      const hash = location.hash.replace('#', '');
      hashNavigate(hash);
    }

  }, []);

  React.useEffect(() => {

    if (!!location.hash && !isTransitioning) {
      const hash = location.hash.replace('#', '');
      const scrollToElement = document.getElementById(hash);

      if ( scrollToElement ) {
        const elementScrollPosition = topPosition(scrollToElement as Element);
        const headerOffset = (windowWidth() > 1024 || elementScrollPosition < scrollTop()) ? 90 : 0;
        const scrollPosition = topPosition(scrollToElement as Element) - headerOffset;
        ;
        smoothScrollToY(scrollPosition);
      }
    }

  }, [location.hash, isTransitioning]);

  const onExit = useCallback(() => {
    setIsTransitioning(true);

    if ($html.current) {
      $html.current.classList.add('page-transitioning');
    }
  }, []);

  const onExited = useCallback(() => {

    if ($html.current) {
      $html.current.style.top = '';
      hasWindow && window.scrollTo(0, 0);
    }
    !!setHeaderVisible && setHeaderVisible(true);
  }, [setHeaderVisible]);

  const onEntered = useCallback(() => {

    if ($html.current) {
      $html.current.classList.remove('page-transitioning');
    }
    setIsTransitioning(false);
    !!setHeaderVisible && setHeaderVisible(true);

    if (!!location.hash) {
      const hash = location.hash.replace('#', '');
      const scrollToElement = document.getElementById(hash);

      if ( scrollToElement ) {
        const headerOffset = 90;
        const scrollPosition = topPosition(scrollToElement as Element) - headerOffset;
        ;
        smoothScrollToY(scrollPosition);
      }
    }
  }, [location.hash, setHeaderVisible]);

  return (
    <TransitionGroup>
      <SwitchTransition>
        <CSSTransition
          timeout={350}
          key={location.pathname}
          onExit={onExit}
          onExited={onExited}
          onEntered={onEntered}
        >
          <StyledPageTransition>
            {props.children && props.children}
          </StyledPageTransition>
        </CSSTransition>
      </SwitchTransition>
    </TransitionGroup>
  );
};
