// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-blog-index-page-tsx": () => import("./../../../src/templates/blog-index-page.tsx" /* webpackChunkName: "component---src-templates-blog-index-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-site-map-page-tsx": () => import("./../../../src/templates/site-map-page.tsx" /* webpackChunkName: "component---src-templates-site-map-page-tsx" */)
}

