import { ErrorMessage } from 'formik';
import * as React from 'react';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';
import { ThemeProvider } from 'styled-components';
import { inViewTransitionTimingValue } from '../../global/animation.styles';
import { siteTheme } from '../../global/theme.styles';
import { Label } from '../label';
import { IBaseFieldProps } from './base-field';
import { StyledFieldWrapper } from './field-wrapper.styles';

export interface IFieldWrapperProps extends IBaseFieldProps {
  touched?: boolean;
  filled?: boolean;
  error?: string;
  showLabel?: boolean;
}

export const FieldWrapper: React.FC<IFieldWrapperProps> = ({ label, ...props }) => {
    return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
      <StyledFieldWrapper touched={props.touched} filled={props.filled} error={!!props.error}>
        <Label htmlFor={props.id || props.name} showLabel={props.showLabel}>{label}</Label>
          {props.children}
        <span className="text-input__underline" />
        <div className="error">
          <SwitchTransition>
            <CSSTransition 
              key={props.error}
              classNames={'error__value'}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
            >
              <div className="error__value">
                {props.touched && props.error && props.error}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </StyledFieldWrapper>
    </ThemeProvider>
  );
};
