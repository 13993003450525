import React from 'react';
import { scrollLeft, scrollTop } from './positions';

export const isSmoothScrollSupported = () => 'scrollBehavior' in document.documentElement.style;

export const TimingFunction = (t: number) => t;

function easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

function easeInOutSine(x: number): number {
    return -(Math.cos(Math.PI * x) - 1) / 2;
}

export function smoothScrollToY(scrollTo: number, duration = 300) {
    if (typeof window !== 'undefined') {
        const timing = TimingFunction;
        let startPos = scrollTop();
        let startTime = 0;
        scrollTo = scrollTo - startPos;

        const step = (timestamp: number) => {
            startTime = startTime || timestamp;
            const progress = timestamp - startTime;
            const time = Math.min(1, (timestamp - startTime) / duration);

            window.scrollTo(0, startPos + timing(time) * scrollTo);

            if (progress < duration) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    }
}

export const smoothScrollToX = (scrollTo: number, container?: Element, duration = 300) => {
    const scrollArea = container || window;

    if (isSmoothScrollSupported()) {
        scrollArea.scrollTo({
            left: scrollTo,
            behavior: 'smooth'
        })
    } else {
        if (typeof window !== 'undefined') {
            const timing = easeInOutSine;
            const scrollArea = container || window;
            let startPos = scrollLeft(container);
            let startTime = 0;
            scrollTo = scrollTo - startPos;
    
            const step = (timestamp: number) => {
                startTime = startTime || timestamp;
                const progress = timestamp - startTime;
                const time = Math.min(1, (timestamp - startTime) / duration);
                scrollArea.scrollTo(Math.round(startPos + timing(time) * scrollTo), 0);
    
                if (progress < duration) {
                    window.requestAnimationFrame(step);
                }
            };
    
            window.requestAnimationFrame(step);
        }
    }
};