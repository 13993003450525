import React from "react";
import { useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { validateFileField } from "../../helpers/input";
import { FieldWrapper } from "./field-wrapper";
import { StyledButton } from "../../parts/button.styles";
import { StyledFileField } from "./file-field.styles";

export interface IFileFieldProps extends IBaseFieldProps {
}

export const FileField: React.FC<IFileFieldProps> = ({ label, ...props }) => {
  const fileInput = React.useRef<HTMLInputElement>(null);

  const fileFieldValidator = React.useCallback((value: File) => {
    return validateFileField(value, props.required);
  }, [props.required]);

  const [field, meta, helpers] = useField({ name: props.name, validate: fileFieldValidator });

  return (
    <StyledFileField>
      <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error} showLabel={true}>
        <input
          type="file"
          onChange={(event) => {
            event.currentTarget.files && helpers.setValue(event.currentTarget.files[0]);
          }}
          ref={fileInput}
          style={{ visibility: 'hidden' }}
        />

        <StyledButton secondary={true} type="button" onClick={() => fileInput.current?.click()}>
          Upload File
        </StyledButton>
        <div className="file-field__value">
          {!!field.value &&
            <small>Selected: {field.value.name}</small>
          }
          {meta.touched && !field.value &&
            <small>No file chosen</small>
          }
        </div>
      </FieldWrapper>
    </StyledFileField>
  );
};