import React from "react";
import { StyledModal } from "./modal.styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ContactModal from "./contact";
import ApplyModal from "./apply";
import ImageGalleryModal from "./image-gallery";
import { VideoModal } from "./video";
import { FormContextProvider } from "../forms/form.context";
import { useModalContext } from "../global/contexts/modal.context";

export interface IModalProps {
    hash?: string;
    hashProps?: string[];
}

export type ModalComponents = Record<string, React.ComponentType>;

export const modals: ModalComponents = {
    contact: ContactModal,
    apply: ApplyModal,
    'image-gallery': ImageGalleryModal,
    video: VideoModal,
};

const Modal: React.FC = props => {
    const modalRef = React.useRef<React.ComponentType | undefined>(undefined);
    const { isModalOpen, modalId } = useModalContext();

    modalRef.current = React.useMemo(
        () =>
          modalId ? modals[modalId] : undefined,
        [modalId, modals]
      );


    const onEnterHandler = () => {
    // modalContext.onAnimationStateChange('enter');
    };

    const onEnteringHandler = () => {
    // modalContext.onAnimationStateChange('entering');
    };

    const onEnteredHandler = () => {
    // modalContext.onAnimationStateChange('entered');
    };

    const onExitHandler = () => {
    // modalContext.onAnimationStateChange('exit');
    };

    const onExitingHandler = () => {
    // modalContext.onAnimationStateChange('exiting');
    };

    const onExitedHandler = () => {
    // modalContext.onAnimationStateChange('exited');
    };

    return (
        <TransitionGroup>
            <CSSTransition
                timeout={350}
                onEnter={onEnterHandler}
                onEntering={onEnteringHandler}
                onEntered={onEnteredHandler}
                onExit={onExitHandler}
                onExiting={onExitingHandler}
                onExited={onExitedHandler}
            >
                <StyledModal isOpen={!!isModalOpen ? isModalOpen : false} isMedia={modalId == 'video' || modalId == 'image-gallery'}>
                    <div className="modal__background" />
                    <div className="modal__content">
                        <FormContextProvider>
                        {modalRef.current && <modalRef.current />}
                        </FormContextProvider>
                    </div>
                </StyledModal>
            </CSSTransition>
        </TransitionGroup>
    );
}

export default Modal;
