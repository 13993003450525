import { css } from "styled-components";
import { media } from "./media.styles";
import { colors } from "./theme.styles";

export const facebookIconLightStyles = css`

.facebook-icon__path {
    fill: ${colors.white};
  }
`;

export const facebookIconDarkStyles = css`

  .facebook-icon__path {
    fill: ${colors.black};
  }
`;

export const facebookIconColorStyles = css`

  .facebook-icon__path {
    fill: ${colors.fbBlue};
  }
`;

export const linkedinIconLightStyles = css`

  .linkedin-icon__path {
    fill: ${colors.white};
  }
`;

export const linkedinIconDarkStyles = css`

  .linkedin-icon__path {
    fill: ${colors.black};
  }
`;

export const linkedinIconColorStyles = css`

  .linkedin-icon__path {
    fill: ${colors.linkedInBlue};
  }
`;

export const googleIconLightStyles = css`

  .google-icon__path--1 {
    fill: ${colors.white};
  }
  
  .google-icon__path--2 {
    fill: ${colors.whitePartialOpacity};
  }

  .google-icon__path--3 {
    fill: ${colors.white};
  }

  .google-icon__path--4 {
    fill: ${colors.whitePartialOpacity};
  }
`;

export const googleIconDarkStyles = css`

  .google-icon__path {
    fill: ${colors.black};
  }
`;

export const googleIconColorStyles = css`
`;

export const twitterIconDarkStyles = css`

  .twitter-icon__path {
    fill: ${colors.black};
  }
`;

export const twitterIconColorStyles = css`

  .twitter-icon__path {
    fill: ${colors.twitterBlue};
  }
`;

export const SOLSVGString = "<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 749.81 751.6'><path fill='rgb(0,0,0)' id='rect14795' d='M276.27,0V204.52L99.08,102.19.19,273.47,177.37,375.8.19,478,99.08,649.27,276.27,546.94V751.6H474.06V547.08L651.11,649.27,750,478,572.81,375.8,750,273.47,651.11,102.19l-177,102.19V0Z' transform='translate(-0.19)'/></svg>";

export const SOLSVGStringWhite = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 749.81 751.6'><path fill='rgb(255,255,255)' d='M276.27,0V204.52L99.08,102.19.19,273.47,177.37,375.8.19,478,99.08,649.27,276.27,546.94V751.6H474.06V547.08L651.11,649.27,750,478,572.81,375.8,750,273.47,651.11,102.19l-177,102.19V0Z' transform='translate(-0.19)'/></svg>`;
