import * as React from 'react';
import { useEffect } from 'react';
import { StyledCarouselSlide } from './slide.styles';

export interface ICarouselSlideProps {
    slideId: number;
    isNextSlide?: boolean;
    scrollToSlide?: (newCurrentSlide: HTMLDivElement, nextSlideIndex: number) => void;
    observer?: IntersectionObserver;
    inModal?: boolean;
}

export const CarouselSlide: React.FC<ICarouselSlideProps> = props => {
    const slideRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Observe slide for when in view
        // const slideElementId = getSlideIdFromElement(slideRef.current);
        // console.log(`Observing element with slideId: ${props.slideId} and elementId: ${slideElementId}`);
        !!props.observer && !!slideRef.current && props.observer.observe(slideRef.current);
        
        return () => {
            // console.log(`Un-Observing element with slideId: ${props.slideId} and elementId: ${slideElementId}`);

            !!props.observer && !!slideRef.current && props.observer.unobserve(slideRef.current);
        };
    }, [props.observer, slideRef]);

    useEffect(() => {
         !!slideRef.current && props.isNextSlide && props.scrollToSlide && props.scrollToSlide(slideRef.current, props.slideId);
        
        return () => {
        };
    }, [props.isNextSlide, slideRef]);

    return (
        <StyledCarouselSlide 
            inModal={props.inModal}
            className={`slide slide--${props.slideId}`}
            id={`slide--${props.slideId}`}
            ref={slideRef}
        >
            <div className="slide__content">
            {props.children}
            </div>
        </StyledCarouselSlide>
  );
};
