import * as React from 'react';
import { StyledLink } from './link.styles';
import { getLinkTypeProps } from '../helpers/links';
import { Link } from 'gatsby';

export interface ILinkProps {
  to: string;
  className?: string;
  onClick?: () => void;
  cmsContent?: boolean;
}

export const LinkPart: React.FC<ILinkProps> = props => {
  const linkTypeProps = getLinkTypeProps(props.to);

  return (
    <StyledLink className={props.className} cmsContent={props.cmsContent}>
      {linkTypeProps.isGatsby ?
        <Link to={linkTypeProps.to}>{props.children}</Link> 
        :
      <a href={linkTypeProps.to ? linkTypeProps.to : ""} target={linkTypeProps.isExternal ? '__blank' : ''} >{props.children}</a>
      }
    </StyledLink>
  );
};
