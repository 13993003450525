import * as React from 'react';
import { StyledLabel } from './label.styles';

export interface ILabelProps {
    htmlFor?: string;
    showLabel?: boolean;
}

export const Label: React.FC<ILabelProps> = props => {
    return (
    <StyledLabel htmlFor={props.htmlFor} showLabel={props.showLabel}>
        {props.children}
    </StyledLabel>
  );
};