import styled from "styled-components";
import { smallParagraphTextStyles } from "../../global/typography.styles";

export interface IStyledFileField {
  touched?: boolean
  filled?: boolean
  error?: boolean
}

export const StyledFileField = styled.div<IStyledFileField>`
    input {
        height: 0px;
        width: 0px;
    }

    .file-field__value {
      ${smallParagraphTextStyles}
    }
`
