import React from 'react';
import { useLocation } from '@reach/router';
import { hasWindow, hasDocument } from '../../helpers/window';
import { scrollTop } from '../../helpers/positions';

export type INavState = Partial<ReturnType<typeof useNavState>>;

export const NavContext = React.createContext<INavState | null>(
null
);

export const useNavState = () => {
    const $html = React.useRef<HTMLElement | null>(null);
    const location = useLocation();
    const [
        isNavOpen,
        setIsNavOpen,
    ] = React.useState(false);
    const [
        pagePosition,
        setPagePosition,
    ] = React.useState(0);

    React.useEffect(() => {
        if (hasDocument) {
            $html.current = document.documentElement;
        }
    }, []);

    const toggleNavState = React.useCallback(
        (isNavOpen: boolean) => {
            setIsNavOpen(!isNavOpen);
        },
        [isNavOpen]
    );

    React.useEffect(() => {
        if ($html.current) {
            if (isNavOpen) {
                const scrollPosiiton = scrollTop();
                setPagePosition(scrollPosiiton);
                $html.current.style.top = `-${scrollPosiiton}px`;
                $html.current.classList.add('nav-open');
                hasWindow && window.scrollTo(0, 0);
            } else {
                $html.current.classList.remove('nav-open');
                $html.current.style.top = '';
                hasWindow && window.scrollTo(0, pagePosition);
            }
        }},
        [isNavOpen]
    );

    React.useEffect(() => {
        isNavOpen && setIsNavOpen(false);
        },
        [location.pathname]
    );

    return {
        isNavOpen,
        toggleNavState,
    };
};

export const useNavContext = () => {
    const navContext = React.useContext(NavContext);

    if (!navContext) {
        throw new Error(
        'NavMeta Context used outside of NavMetaContext.Provider'
        );
    }

    return navContext;
};

export const NavContextProvider: React.FC = ({ children }) => {
    const navState = useNavState();

    return (
        <NavContext.Provider value={navState}>
            {children}
        </NavContext.Provider>
    );
};
  