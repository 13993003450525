import * as React from 'react';
import { ImagePart, ImageRatios } from '../parts/image';
import { StyledGallerySlider } from './gallery-slider.styles';
import { Carousel } from './carousel';
import { ImageGalleryBlockImagesFragmentFragment, Maybe } from '../../gatsby-graphql';

export interface IGallerySliderProps {
    images: Maybe<ImageGalleryBlockImagesFragmentFragment>[],
    initialSlide?: number,
    inModal?: boolean,
    hasSlideCount?: boolean;
}
export const GallerySlider: React.FC<IGallerySliderProps> = props => {

    return (
        <StyledGallerySlider inModal={props.inModal} >
            <Carousel 
                initialSlide={props.initialSlide}
                hasSlideCount={props.hasSlideCount}
                inModal={props.inModal}
                hasButtons={true}
                slides={props.images && props.images.map((image, index) => {
                let ratio: ImageRatios = 'default';

                return (
                    <ImagePart
                        src={image?.src?.childImageSharp?.gatsbyImageData}
                        caption={image?.caption || undefined}
                        ratio={ratio}
                        key={index}
                        priority={props.inModal ? 'eager' : 'lazy'}
                    />
                )
                })} 
            />
        </StyledGallerySlider>
  );
};
