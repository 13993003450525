import styled, { css } from 'styled-components';
import { font } from './fonts.styles';
import { media } from './media.styles';
import { colors, themedLinks } from './theme.styles';
import { animation } from './animation.styles';

export const h1Styles = css`
  font-family: ${font.primary};
  font-size: ${font.size.header};
  font-weight: 500;
  line-height: 86px;

  @media ${media.desktop} {
    font-size: ${font.size.huge};
    line-height: 75px;
  }
`;

export const fancyH1Styles = css`
  font-family: ${font.fancy};
  font-size: ${font.size.header};
  font-weight: 500;
  line-height: 70px;

  @media ${media.desktop} {
    font-size: ${font.size.huge};
    line-height: 75px;
  }
`;

export const h2Styles = css`
  font-family: ${font.secondary};
  font-size: ${font.size.smallHeader};
  font-weight: 800;
  line-height: 60px;
  margin: 0;

  @media ${media.desktop} {
    font-size: ${font.size.header};
    margin: 0.5em 0;
    line-height: 75px;
  }
`;

export const h3Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.large};

  @media ${media.desktop} {
    font-size: ${font.size.title};
  }
`;

export const h4Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.primary};

  @media ${media.desktop} {
    font-size: ${font.size.large};
  }
`;

export const h5Styles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.normal};

  @media ${media.desktop} {
    font-size: ${font.size.large};
  }
`;

export const navStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.smallHeader};
  font-weight: 500;
  line-height: 65px;

  @media ${media.tablet} {
    font-size: ${font.size.header};
    line-height: 75px;
  }
`;

export const LinkStyles = css`
  ${themedLinks}
  text-decoration: none;
  border-bottom: 0px solid ${colors.brandBlue};
  transition: border-bottom ${animation.hover}, color ${animation.hover};
`;

export const FooterLinkStyles = css`
  ${themedLinks};
  font-family: ${font.primary};
  text-decoration: none;
  transition: color ${animation.hover};


`;

export const specialtyStyles = css`
  font-family: ${font.secondary};
  font-size: ${font.size.tiny};
  line-height: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media ${media.desktop} {
    font-size: ${font.size.small};
    letter-spacing: 2.5px;
    line-height: 11px;
  }
`;

export const boldParagraphStyles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.large};
  line-height: 30px;
  margin-bottom: 20px;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
    margin-bottom: 25px;
  }
`;

export const paragraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.large};
  font-weight: 300;
  line-height: 30px;
  margin-bottom: 20px;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
    margin-bottom: 25px;
  }
`;

export const smallParagraphTextStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.normal};
  line-height: 25px;

  @media ${media.desktop} {
    font-size: ${font.size.primary};
    line-height: 30px;
  }
`;

export const smallParagraphStyles = css`
  ${smallParagraphTextStyles}
  margin-bottom: 16px;

  @media ${media.desktop} {
    margin-bottom: 18px;
  }
`;

export const quoteStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.title};

  @media ${media.tabletLandscape} {
    font-size: ${font.size.largeTitle};
  }

  @media ${media.desktop} {
    font-size: ${font.size.smallHeader};
  }
`;

export const largeParagraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.largeTitle};
  line-height: 35px;

  @media ${media.desktop} {
    font-size: ${font.size.smallHeader};
    line-height: 45px;
  }
`;

export const bragParagraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.smallHeader};
  line-height: 45px;

  @media ${media.desktop} {
    font-size: ${font.size.header};
    line-height: 55px;
  }
`;

export const HugeParagraphStyles = css`
  font-family: ${font.primary};
  font-size: ${font.size.header};
  line-height: 55px;

  @media ${media.desktop} {
    font-size: ${font.size.huge};
    line-height: 75px;
  }
`;

export const LabelTextStyles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.large};
  line-height: 30px;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
  }
`;

export const InputTextStyles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.large};
  line-height: 30px;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
  }
`;

export const BlogAttributionTextStyles = css`
  font-family: ${font.fancy};
  font-size: ${font.size.large};
  line-height: 30px;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
  }
`;

export const FooterLabelTextStyles = css`
  font-family: ${font.secondary};
  font-size: ${font.size.small};
  font-weight: 500;
  line-height: 30px;

  @media ${media.desktop} {
    font-size: ${font.size.normal};
    line-height: 35px;
  }
`;

export const ButtonTextStyles = css`
  font-family: ${font.primaryBold};
  font-size: ${font.size.large};
  line-height: 30px;
  text-align: center;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 35px;
  }
`;

export const Heading1 = styled.h1`
  ${h1Styles}
`;

export const Heading2 = styled.h2`
  ${h2Styles}
`;

export const Heading3 = styled.h3`
  ${h3Styles}
`;

export const Heading4 = styled.h4`
  ${h4Styles}
`;

export const Heading5 = styled.h5`
  ${h5Styles}
`;

export const StyledParagraph = styled.p`
  ${smallParagraphStyles}
`;

export const StyledLargeParagraph = styled.p`
  ${paragraphStyles}
`;

export const StyledLabel = styled.label`
  ${LabelTextStyles}
  display: block;
  margin-bottom: 18px;

  @media ${media.desktop} {
    margin-bottom: 22px;
  }
`;

export const StyledBoldParagraph = styled.p`
  ${boldParagraphStyles}
`;

export const unorderedList = css`
  font-family: ${font.secondary};
  font-size: ${font.size.large};
  font-weight: 500;
  list-style-type: none;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 30px;
  }

  li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 25px;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      padding: 0;

      @media ${media.desktop} {
        top: -2px;
      }
    }
  }
`;

export const orderedList = css`
  font-family: ${font.primary};
  font-size: ${font.size.large};
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 22px;
  margin-left: 16px;
  counter-reset: step-counter;

  @media ${media.desktop} {
    font-size: ${font.size.title};
    line-height: 30px;
  }

  li {
    position: relative;
    margin-bottom: 5px;

    &::before {
      position: absolute;
      left: -16px;
      content: counter(step-counter)'. ';
      counter-increment: step-counter;
    }
  }

  & ol {
    counter-reset: nested-step-counter;

    li {
    position: relative;
    margin-bottom: 5px;
    margin-left: 15px;
    font-size: ${font.size.primary};

    @media ${media.desktop} {
      font-size: ${font.size.large};
      line-height: 30px;
    }

      &::before {
        position: absolute;
        left: -16px;
        content: counter(nested-step-counter)'. ';
        counter-increment: nested-step-counter;
      }
    }
  }
`;

export const hiddenLabelTextStyle = css `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;
