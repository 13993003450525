import { graphql } from "gatsby";
import React from "react";
import { MenuItemFragmentFragment, MenuItemsFragmentFragment } from "../../gatsby-graphql";
import { LinkPart } from "../parts/link";
import MenuRenderer from "./menu-renderer";

export interface IMenuItemProps extends MenuItemsFragmentFragment {
    listItemClass?: string;
 };

const MenuItem: React.FC<IMenuItemProps> = props => {
    const url = props?.connectedNode?.node?.slug ? `/${props.connectedNode.node.slug}` : (props.url || '');

    return (
        <li className={props.listItemClass}>
            <LinkPart to={url} >
                {props.label}
            </LinkPart>
            {props?.childItems !== undefined && props?.childItems?.nodes && props.childItems?.nodes.length > 0 &&
                <MenuRenderer menuItems={props?.childItems.nodes} parentId={props.id} listItemClass={`${props.listItemClass}--sub-item`} key={2}/>
            }
        </li>
    );
}

export default MenuItem;
