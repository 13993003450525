import styled from 'styled-components';
import { hiddenLabelTextStyle, LabelTextStyles } from '../global/typography.styles';

export interface IStyledLabelProps {
    showLabel?: boolean;
}
export const StyledLabel = styled.label<IStyledLabelProps>`
    ${LabelTextStyles}
    ${p => p.showLabel ? '' : hiddenLabelTextStyle}
`;
