import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { NoScrollBarStyle } from '../../global/placeholders';

export interface IStyledCarouselTrackProps {
    slideCount?: number;
    inModal?: boolean;
}

export const StyledCarouselTrack = styled.div<IStyledCarouselTrackProps>`
    ${NoScrollBarStyle}
    width: 100%;
    height: 100%;
    display: grid;
    column-gap: 50px;
    grid-template-columns: ${p => `repeat(${p.slideCount}, 1fr)`};
    overflow-x: scroll;
    overflow-y: ${p =>  p.inModal ? 'hidden' : 'visible'};
    scroll-snap-type: x mandatory;
    transform: translate3d(0, 0, 0);
    counter-reset: slideCount;

    @media ${media.tabletLandscape} {
        column-gap: 100px;
    }
`;
