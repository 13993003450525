import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { validatePhoneField } from "../../helpers/input";
import { FieldWrapper } from "./field-wrapper";

export interface IPhoneFieldProps extends IBaseFieldProps {
}

export const PhoneField:React.FC<IPhoneFieldProps> = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const phoneFieldValidator = React.useCallback((value: string) => {
      return validatePhoneField(value, props.required);
    }, [props.required]);

    const [field, meta] = useField({ name: props.name, validate: phoneFieldValidator});

    return (
      <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error}>
        <Field {...field} {...props} 
          className="phone-input" 
          validate={phoneFieldValidator} 
          type="tel"
        />
      </FieldWrapper>
    );
  };