import styled from 'styled-components';
import { INavigationButtonProps, InPageNavigationButton, ModalNavigationButton } from '../../global/placeholders';

export interface IStyledCarouselNavigationButtonProps extends INavigationButtonProps {
    inModal?: boolean;
}

export const StyledCarouselNavigationButton = styled.div<IStyledCarouselNavigationButtonProps>`
    ${p =>  p.inModal ? ModalNavigationButton : InPageNavigationButton};
`;
