import { css, keyframes } from 'styled-components';

export type transitionTypes = 'base' | 'hover' | 'inView' | 'header' | 'modal' | 'page' | 'navButton';

export type TransitionStyles = 'fade-in' | 'fade-up' | 'fade-side' | 'none';

export const baseTransitionTimingValue = 350;
export const hoverTransitionTimingValue = 250;
export const inViewTransitionTimingValue = 500;
export const modalTransitionTimingValue = 500;
export const pageTransitionTimingValue = 500;
export const navButtonTransitionTimingValue = 300;

export const baseEasingFunction = 'cubic-bezier(0.33, 1, 0.68, 1)';

export const transitionTiming = (
  type: transitionTypes,
  multiplier: number = 1
) => {
  let timingValue = baseTransitionTimingValue;

  switch (type) {
    case 'hover':
      timingValue = hoverTransitionTimingValue;
      break;
    case 'inView':
        timingValue = inViewTransitionTimingValue;
        break;
    case 'modal':
      timingValue = modalTransitionTimingValue;
      break;
    case 'page':
        timingValue = pageTransitionTimingValue;
        break;
    case 'navButton':
        timingValue = navButtonTransitionTimingValue;
        break;
    default:
      timingValue = baseTransitionTimingValue;
      break;
  }

  return timingValue * multiplier + 'ms';
};

export const animation = {
  hover: `${transitionTiming('hover')} ${baseEasingFunction}`,
  hoverSecondary: `${transitionTiming('hover', 1.2)} ${baseEasingFunction}`,
  hoverImageZoom: `${transitionTiming('hover', 2)} ${baseEasingFunction}`,
  inView: `${transitionTiming('inView')} ${baseEasingFunction}`,
  inViewSecondary: `${transitionTiming('inView', 1.2)} ${baseEasingFunction}`,
  header: `${transitionTiming('header')} ${baseEasingFunction}`,
  modal: `${transitionTiming('modal')} ${baseEasingFunction}`,
  page: `${transitionTiming('page')} ${baseEasingFunction}`,
  navButton: `${transitionTiming('navButton')} ${baseEasingFunction}`,
  hamButtonLine: `${transitionTiming('navButton', 0.333)} ${baseEasingFunction}`,
  closeButtonLine: `${transitionTiming('navButton', 0.5)} ${baseEasingFunction}`,
};

export const glitchOne = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(10px, 0px, 0px);
  }

  15% {
    opacity: 0;
    transform: translate3d(0px, 0px, 0px);
  }

  30% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }

  45% {
    opacity: 1;
    transform: translate3d(-5px, 0px, 0px);
  }

  60% {
    opacity: 1;
    transform: translate3d(-5px, 0px, 0px);
  }

  75% {
    opacity: 1;
    transform: translate3d(25px, -20px, 0px);
  }

  90% {
    opacity: 1;
    transform: translate3d(-5px, 0px, 0px);
  }
`;
