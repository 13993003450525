export const font = {
    primary: `'din-condensed', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    primaryBold: `'din-condensed', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    secondary: `'neue-haas-grotesk-display', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
    fancy: `'neue-haas-grotesk-display', arial, 'Helvetica Neue', Helvetica, Arial, sans-serif`,
  
    size: {
        huge: '90px',
        header: '70px',
        smallHeader: '55px',
        largeTitle: '35px',
        title: '25px',
        large: '20px',
        primary: '18px',
        normal: '16px',
        small: '13px',
        tiny: '11px',
    }
  };
