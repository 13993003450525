import styled from 'styled-components';

export const StyledImageGalleryModal = styled.div`
    position: relative;
    height: 100%;

    & .image-gallery-modal__container {
        height: 100%;
    }
`;
