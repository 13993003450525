import React, { useEffect } from "react";
import { useModalContext } from "../global/contexts/modal.context";
import { IGallery, useGalleryContext } from "../global/gallery.context";
import { GallerySlider } from "../parts/gallery-slider";
import { StyledImageGalleryModal } from "./image-gallery.styles";

const ImageGalleryModal: React.FC = props => {
    const id = 'image-gallery';
    const { modalProps } = useModalContext();
    const { galleryState } = useGalleryContext();
    const [currentGallery, setCurrentGallery] = React.useState<IGallery>();
    const galleryID = modalProps?.hashProps && modalProps.hashProps[0];
    const galleryPhoto = modalProps?.hashProps && modalProps.hashProps[1];

    useEffect(() => {
        !!galleryState && !currentGallery && setCurrentGallery(galleryState.find(gallery => gallery.id.toString() === galleryID));

        return () => {
        };
    }, [galleryState]);

    return (
        <StyledImageGalleryModal>
            {!!galleryID && 
                <div className='image-gallery-modal__container'>
                    {!!currentGallery && <GallerySlider images={currentGallery.images} initialSlide={!!galleryPhoto ? parseInt(galleryPhoto) : 0} inModal={true} />}
                </div>
            }
        </StyledImageGalleryModal>
    );
}

export default ImageGalleryModal;
