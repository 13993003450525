import styled from 'styled-components';
import { animation } from '../global/animation.styles';
import { colors, layer, opacity, themedBackground } from '../global/theme.styles';
import { boldParagraphStyles, h2Styles, smallParagraphTextStyles } from '../global/typography.styles';
import { StyledButton } from '../parts/button.styles';

export interface IStyledFormProps {
    isSuccess?: boolean;
}

export const StyledForm = styled.div<IStyledFormProps>`
    ${themedBackground}
    width: 100%;
    max-width: 500px;
    min-height: 100vh;
    padding: 0px 25px;
    padding-bottom: 25px;
    margin: auto;
    text-align: center;
    transform: translate3d(0, 0, 0);

    .form {

        &__content {
            display: ${p => p.isSuccess ? 'none' : 'initial'};
            opacity: ${p => p.isSuccess ? '0' : '1'};
            transition: opacity ${animation.modal};
        }

        &__success-message {
            ${boldParagraphStyles}
            display: ${p => p.isSuccess ? 'initial' : 'none'};
            opacity: ${p => p.isSuccess ? '1' : '0'};
            transition: opacity ${animation.modal};
        }
    }

    .form-text {
        ${smallParagraphTextStyles}
    }

    h3 {
        ${h2Styles}
        text-align: center;
        text-transform: capitalize;
        padding-bottom: 25px;
    }

    & ${StyledButton} {
        z-index: ${layer.modalButton};

        .submit-value {

            &-exit {
                transition: opacity ${animation.inView}, transform ${animation.inViewSecondary};
                opacity: ${opacity.none};
                transform: translate3d(0, 0, 0);
                overflow-x: hidden;

                &-active {
                    opacity: ${opacity.full};
                    max-width: 100vw;
                    transform: translate3d(25px, 0, 0);
                    overflow-x: hidden;
                }
            }

            &-enter {
                opacity: ${opacity.full};
                max-width: 100vw;
                transform: translate3d(-25px, 0, 0);
                transition: opacity ${animation.inView}, transform ${animation.inViewSecondary};

                &-active {
                    opacity: ${opacity.none};
                    transform: translate3d(0, 0, 0);
                    overflow-x: hidden;
                }
            }
        }
    }
`;
