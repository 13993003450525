import { graphql } from "gatsby";
import React from "react";
import { MenuItemsFragmentFragment } from "../../gatsby-graphql";
import MenuItem from "./menu-item";

export interface IMenuRendererProps {
    menuItems: (MenuItemsFragmentFragment | null)[];
    parentId?: string;
    listClass?: string;
    listItemClass?: string;
}

const MenuRenderer: React.FC<IMenuRendererProps> = props => {

    return (
    <ul className={props.listClass}>
        {props.menuItems && props.menuItems.map((item) => {
            return item && (!item?.parentId || item?.parentId === props.parentId) &&
                <MenuItem {...item}  listItemClass={props.listItemClass} key={item?.id}/>
        })}
    </ul>
    );
}

export default MenuRenderer;

export const MenuItemFragment = graphql`
    fragment MenuItemFragment on WpMenuItem {
        databaseId
        title
        url
        label
        id
        parentId
        connectedNode {
            node {
                ... on WpPost {
                    id
                    slug
                }
                ... on WpPage {
                    id
                    slug
                }
            }
        }
    }
`;

export const MenuItemsFragment = graphql`
    fragment MenuItemsFragment on WpMenuItem {
        ...MenuItemFragment
        childItems {
            nodes {
                ...MenuItemFragment
            }
        }
    }
`;
