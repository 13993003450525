import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { validateEmailField } from "../../helpers/input";
import { FieldWrapper } from "./field-wrapper";

export interface IEmailFieldProps extends IBaseFieldProps {
}

export const EmailField:React.FC<IEmailFieldProps> = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const emailFieldValidator = React.useCallback((value: string) => {
      return validateEmailField(value, props.required);
    }, [props.required]);

    const [field, meta, helpers] = useField({ name: props.name, validate: emailFieldValidator});

    return (
      <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error}>
        <Field {...field} {...props} 
          className="email-input" 
          type="email"
          validate={emailFieldValidator} 
        />
      </FieldWrapper>
    );
  };