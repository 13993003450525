import styled from 'styled-components';
import { colors, layer, themedBackground, themedColor, themedLinks } from '../global/theme.styles';
import { navStyles } from '../global/typography.styles';
import { baseEasingFunction } from '../global/animation.styles';
import { media } from '../global/media.styles';

export interface IStyledFlyoutProps {
    isOpen: boolean;
}

export const StyledFlyout = styled.div<IStyledFlyoutProps>`
    ${themedColor};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    text-align: right;
    text-transform: uppercase;
    z-index: ${layer.modal};
    pointer-events: ${p => p.isOpen ? 'all' : 'none'};

    & .flyout__background,
    & .flyout__content {
        position: absolute;
        width: 100%;
        opacity: ${p => p.isOpen ? '1' : '0'};
        transition: opacity 350ms ${baseEasingFunction};
    }

    & .flyout__background {
        ${themedBackground};
        height: 100%;
        z-index: ${layer.modalBackground};
    }

    & .flyout__content {
        padding: 30px;
        z-index: ${layer.modalContent};

        @media ${media.tablet} {
            padding: 50px;
        }
    }

    & ul {
        margin-top: 90px;
        transform: ${p => p.isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(25px, 0, 0)'};
        transition: transform 350ms ease;
    }

    & li {
        ${navStyles}
    }

    & a {
        ${themedLinks};
        text-decoration: none;
        transition: color 350ms ease;
    }
`;
