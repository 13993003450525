import { IApplyFormState } from '../forms/apply';
import { IContactFormState } from '../forms/contact';
import { FormState } from '../forms/form.context';
import { trackEvent } from './analytics';

export const submitFormData = async (values: IContactFormState | IApplyFormState, formName: string) => {
  const formData = new FormData();
  let formState: FormState = 'Failed';
  formDataBuilder(values, formData);
  let headers = new Headers();
  headers.append("Accept", "application/json");

  await fetch(`/api/${formName}`, {
    method: "POST",
    headers: headers,
    body: formData,
  }).then((res) => {
    if (res.status === 200) {
      trackEvent('submit_lead_success', {
        event_category: 'leads',
        event_label: `Successful ${formName} Form Submission`,
        value: `submit_${formName}_success`
      });

      formState = 'Success';
    } else {
      trackEvent('submit_lead_failure', {
        event_category: 'leads',
        event_label: `Failed ${formName} Form Submission`,
        value: `submit_${formName}_failure`
      });
    }

  }).catch((error) => {
    trackEvent('submit_lead_failure', {
      event_category: 'leads',
      event_label: `Failed ${formName} Form Submission`,
      value: `submit_${formName}_failure`
    });
  });

  return formState;
};

const parseData = (data: object, formData: FormData) => {
  for (const key in data) {
    console.log(key);
    console.log(data[key]);
    formData.append(key, data[key]);
  }
  console.log(formData);
  return formData;
}

export const formDataBuilder = (data: object, formData: FormData) => {
  return parseData(data, formData);
}