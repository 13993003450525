import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { colors, opacity, themedBackground, themedColor } from '../../global/theme.styles';
import { InputTextStyles, LabelTextStyles } from '../../global/typography.styles';

export interface IStyledTextAreaField {
    touched?: boolean;
    filled?: boolean;
    error?: boolean;
}

export const StyledTextAreaField = styled.div<IStyledTextAreaField>`

    & textarea {
        ${themedColor}
        ${themedBackground}
        ${InputTextStyles}
        width: 100%;
        height: 300px;
        border: ${p => p.filled ? `2px solid ${colors.black}` : `2px solid ${colors.lightGray}`};
        border-radius: 0px;
        padding: 15px;
        color: ${p => p.error ? colors.red : ''};
        transition: color ${animation.hover}, background-color ${animation.hover}, border-color ${animation.hover};
        outline: none;
        resize: none;
        -webkit-appearance: none;

        @media (prefers-color-scheme: light) {
            background-color: ${p => p.filled ? colors.white : ''};
            border-color: ${p => p.filled ? colors.black : colors.lightGray};
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${p => p.filled ? colors.black : ''};
            border-color: ${p => p.touched && p.error ? colors.red : p.filled ? colors.white : colors.darkGray};
        }

        &::placeholder {
            ${LabelTextStyles}
            opacity: ${opacity.none};
            transition: opacity ${animation.hover}, color ${animation.hover};

            @media (prefers-color-scheme: light) {
                color: ${p => p.error && p.touched ? colors.red : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.error && p.touched ? colors.red : colors.whitePartialOpacity};
            }
        }

        &:focus::placeholder,
        &:hover:focus::placeholder {
            opacity: ${p => p.filled ? opacity.full : opacity.medium};
        }

        &:focus {
            ${themedColor}
            ${themedBackground}

            @media (prefers-color-scheme: light) {
                background-color: ${colors.white};
                border-color: ${p => p.touched && p.error ? colors.red : colors.black};
            }

            @media (prefers-color-scheme: dark) {
                background-color: ${colors.black};
                border-color: ${p => p.touched && p.error ? colors.red : colors.white};
            }

            &:hover {
                ${themedColor}
            }
        }

        &:hover {
            @media (prefers-color-scheme: light) {
                color: ${p => p.error && p.touched ? colors.red : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.error && p.touched ? colors.red : colors.whiteMediumOpacity};
            }

            &::placeholder {
                opacity: ${opacity.partial};
            }
        }
    }
`;
