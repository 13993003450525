import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { FieldWrapper } from "./field-wrapper";
import { validateToggleField } from "../../helpers/input";
import { IOption } from "./select-field";
import { StyledToggleField } from "./toggle-field.styles";
import { ThemeProvider } from "styled-components";
import { siteTheme } from "../../global/theme.styles";

export interface IToggleFieldProps extends IBaseFieldProps {
    toggleOptions: IOption[];
    default?: string;
}

export const toggleFieldValues = [
    {
        value: "true",
        text: "Yes"
    },
    {
        value: "false",
        text: "No"
    }
];

export const ToggleField:React.FC<IToggleFieldProps> = ({ label, toggleOptions, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const toggleFieldValidator = React.useCallback((value: string) => {
      return validateToggleField(toggleOptions, value, props.required);
    }, [props.required]);
  
    const [field, meta, helpers] = useField({ name: props.name, validate: toggleFieldValidator});

    return (
    <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
    <StyledToggleField>
        <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error} showLabel={true}>
            <div className="toggle__container">
                {toggleOptions.map((option: IOption, index: number) => {
                return (
                    <span key={index}>
                        <Field {...props}
                            type="radio" 
                            className="toggle-input"
                            value={option.value}
                            validate={toggleFieldValidator} 
                            key={index}
                        />
                        <div className="toggle__option">
                            {option.text}
                        </div>
                    </span>
                );
                })}
            </div>
        </FieldWrapper>
      </StyledToggleField>
      </ThemeProvider>
    );
  };