import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { colors, opacity, themedBackground } from '../../global/theme.styles';
import { hiddenLabelTextStyle, LabelTextStyles } from '../../global/typography.styles';
import { StyledLabel } from '../label.styles';

export interface IStyledToggleField {
}

export const StyledToggleField = styled.div<IStyledToggleField>`
    & ${StyledLabel} {
        display: block;
        width: 100%;
    }

    .toggle {
        &__container {
            ${LabelTextStyles}
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 15px;
            text-align: left;

            & span {
                position: relative;
            }

            & span:first-of-type {
                & .toggle__option {

                    &::after {
                        background-color: ${colors.lime};
                    }
                }
            }

            & span:nth-of-type(2) {
                & .toggle__option {
                    &::after {
                        background-color: ${colors.red};
                    }
                }
            }
        }

        &__option {
            ${themedBackground}
            ${LabelTextStyles}
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            z-index: 1;
            pointer-events: none;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: ${opacity.full};
                transition: opacity ${animation.hover};
                z-index: -1;
            }
        }
    }

    input {
        height: 30px;
        width: 100%;
        cursor: pointer;


        &:checked + .toggle__option {
            &::after {
                opacity: ${opacity.none};
            }
        }

        &:hover + .toggle__option {

            &::after {
                opacity: ${opacity.half};
            }
        }
    }
`;
