import styled from 'styled-components';
import { colors, themedColor } from '../global/theme.styles';
import { StyledImage } from './image.styles';

export interface IStyledGallerySliderProps {
    inModal?: boolean;
}

export const StyledGallerySlider = styled.div<IStyledGallerySliderProps>`
    height: 100%;

    & ${StyledImage} {
        & .gatsby-image-wrapper {
            & > div {
                padding-top: 66.6666% !important;
            }
        }
    }

    & figcaption {
        ${themedColor}
        color: ${p =>  p.inModal ? colors.white : ''};
    }
`;
