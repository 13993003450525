import { CareerFragmentFragment, Maybe } from "../../gatsby-graphql";


export const careersToInputValues = (careers: Maybe<CareerFragmentFragment>[]) => {
    return careers && careers.map((career, index: number) => {
        const careerTitle = career && career.slug ? career.slug : '';
        
        return {
            value: careerTitle,
            text: career?.title || ""
        }
    })
}
