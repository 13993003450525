import { css } from 'styled-components';

export const opacity = {
    none: 1,
    partial: 0.8,
    medium: 0.6,
    half: 0.5,
    high: 0.4,
    most: 0.2,
    full: 0,
}

export const blur = {
    base: '15px',
    subtle: '5px'
}

export const colors = {
    black: 'rgba(0, 0, 0, 1)',
    blackPartialOpacity: `rgba(0, 0, 0, ${opacity.partial})`,
    blackMediumOpacity: `rgba(0, 0, 0, ${opacity.medium})`,
    white: 'rgba(255, 255, 255, 1)',
    whiteHover: 'rgba(255, 255, 255, 0.7)',
    whitePartialOpacity: `rgba(255, 255, 255, ${opacity.partial})`,
    whiteMediumOpacity: `rgba(255, 255, 255, ${opacity.medium})`,
    whiteHighOpacity: `rgba(255, 255, 255, ${opacity.high})`,
    whiteMostOpacity: `rgba(255, 255, 255, ${opacity.most})`,
    brandBlue: '#252c64',
    brandBlueHover: '#303a82',
    brandBlueBright: '#596AF0',
    brandBlueDark: '#000028',
    fbBlue: '#1877F2',
    twitterBlue: '#1da1f2',
    linkedInBlue: '#2867B2',
    brandOrange: '#ed6624',
    gold: '#eecc24',
    lime: '#46ee24',
    red: '#ee2445',
    lightGray: 'rgba(230, 230, 230)',
    gray: 'rgba(200, 200, 200)',
    darkGray: 'rgba(30, 30, 30)',
}

export const layer = {
    header: 10000,
    modalContent: 1200,
    modalBackground: -1,
    modal: 1000,
    stickyHeader: 500,
    modalNavigationButton: 200,
    postCardTitle: 150,
    postCardOverlay: 100,
    postCardImage: 50,
    careerButton: 2,
    coverLink: 4,
    youtubeVideoLink: 1,
    modalButton: 1,
    baseLayer: 1,
    buttonBackgroundOverlay: -1,
    buttonBackground: -2,
}

export interface IDynamicColor {
    light: string;
    dark: string;
}

export interface ITheme {
    textColor: IDynamicColor;
    linkColor: IDynamicColor;
    linkHoverColor: IDynamicColor;
    backgroundColor: IDynamicColor;
}

export const primaryComponentTheme: ITheme = {
    textColor: {
        light: colors.black,
        dark: colors.white,
    },
    linkColor: {
        light: colors.brandBlue,
        dark: colors.brandBlueBright,
    },
    linkHoverColor: {
        light: colors.brandOrange,
        dark: colors.brandOrange,
    },
    backgroundColor: {
        light: colors.white,
        dark: colors.black,
    }
}

export const secondaryComponentTheme: ITheme = {
    textColor: {
        light: colors.black,
        dark: colors.white,
    },
    linkColor: {
        light: colors.brandBlue,
        dark: colors.brandBlueBright,
    },
    linkHoverColor: {
        light: colors.brandOrange,
        dark: colors.brandOrange,
    },
    backgroundColor: {
        light: colors.lightGray,
        dark: colors.darkGray,
    }
}

export const tertiaryComponentTheme: ITheme = {
    textColor: {
        light: colors.white,
        dark: colors.white,
    },
    linkColor: {
        light: colors.white,
        dark: colors.white,
    },
    linkHoverColor: {
        light: colors.whiteMediumOpacity,
        dark: colors.whiteMediumOpacity,
    },
    backgroundColor: {
        light: colors.brandBlueDark,
        dark: colors.brandBlueDark,
    }
}

export const footerTheme: ITheme = {
    textColor: {
        light: colors.white,
        dark: colors.white,
    },
    linkColor: {
        light: colors.white,
        dark: colors.white,
    },
    linkHoverColor: {
        light: colors.whiteMediumOpacity,
        dark: colors.whiteMediumOpacity,
    },
    backgroundColor: {
        light: colors.brandBlue,
        dark: colors.brandBlueDark,
    }
}

export const headerTheme: ITheme = {
    textColor: {
        light: colors.black,
        dark: colors.white,
    },
    linkColor: {
        light: colors.brandBlue,
        dark: colors.white,
    },
    linkHoverColor: {
        light: colors.brandOrange,
        dark: colors.brandOrange,
    },
    backgroundColor: {
        light: colors.whitePartialOpacity,
        dark: colors.blackPartialOpacity,
    }
}

export const flyoutTheme: ITheme = {
    textColor: {
        light: colors.black,
        dark: colors.white,
    },
    linkColor: {
        light: colors.brandBlue,
        dark: colors.white,
    },
    linkHoverColor: {
        light: colors.brandOrange,
        dark: colors.brandOrange,
    },
    backgroundColor: {
        light: colors.white,
        dark: colors.black,
    }
}

export const modalTheme: ITheme = {
    textColor: {
        light: colors.brandBlue,
        dark: colors.white,
    },
    linkColor: {
        light: colors.brandBlue,
        dark: colors.white,
    },
    linkHoverColor: {
        light: colors.brandOrange,
        dark: colors.brandOrange,
    },
    backgroundColor: {
        light: colors.whitePartialOpacity,
        dark: colors.blackPartialOpacity,
    }
}

export const siteTheme = {
    primaryComponentTheme: primaryComponentTheme,
    secondaryComponentTheme: secondaryComponentTheme,
    tertiaryComponentTheme: tertiaryComponentTheme,
    headerTheme: headerTheme,
    flyoutTheme: flyoutTheme,
    footerTheme: footerTheme,
    modalTheme: modalTheme,
}

export const themedBackground = css`
    @media (prefers-color-scheme: light) {
        background-color: ${p => p.theme.backgroundColor.light};
    }

    @media (prefers-color-scheme: dark) {
        background-color: ${p => p.theme.backgroundColor.dark};

    }
`;

export const themedColor = css`
    @media (prefers-color-scheme: light) {
        color: ${p => p.theme.textColor.light};
    }

    @media (prefers-color-scheme: dark) {
        color: ${p => p.theme.textColor.dark};
    }
`;

export const themedFill = css`
    @media (prefers-color-scheme: light) {
        fill: ${p => p.theme.textColor.light};
    }

    @media (prefers-color-scheme: dark) {
        fill: ${p => p.theme.textColor.dark};
    }
`;

export const themedLinks = css`
    @media (prefers-color-scheme: light) {
        color: ${p => p.theme.linkColor.light};

        &:hover,
        &:visited {
            color: ${p => p.theme.linkHoverColor.light};
        }

        &:visited:not(:hover) {
            color: ${p => p.theme.linkColor.light};
        }
    }

    @media (prefers-color-scheme: dark) {
        color: ${p => p.theme.linkColor.dark};

        &:hover,
        &:visited {
            color: ${p => p.theme.linkHoverColor.dark};
        }

        &:visited:not(:hover) {
            color: ${p => p.theme.linkColor.dark};
        }
    }
`;
