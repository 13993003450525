import { useStaticQuery, graphql } from "gatsby";
import { CareersQueryQuery } from "../../gatsby-graphql";

export const useCareers = () => {
    const data = useStaticQuery<CareersQueryQuery>(graphql`
        query CareersQuery {
                allWpCareer {
                    nodes {
                    ...CareerFragment
                    }
                }
        }
    `)
  return data.allWpCareer
}

export const CareerCustomFieldsFragment = graphql`
  fragment CareerCustomFieldsFragment on WpCareer_Career {
      hourlyRateMax
      hourlyRateMin
      hoursPerWeekMax
      hoursPerWeekMin
      location
      type
  }
`;

export const CareerFragment = graphql`
    fragment CareerFragment on WpCareer {
        title
        slug
        databaseId
        featuredImage {
            node {
            ...ImageFragment
            }
        }
        career {
            ...CareerCustomFieldsFragment
        }
        blocks {
            __typename
            name
            ...ParagraphBlockFragment
            ...HeadingFragment
            ...ImageBlockFragment
            ...ListBlockFragment
            ...ButtonsBlockFragment
            ...VideoBlockFragment
            ...QuoteBlockFragment
            ...PullQuoteBlockFragment
            ...VerseBlockFragment
            ...EmbedBlockFragment
            ...SpacerBlockFragment
        }
    }
`;
