import React from "react";
import { Field, useField } from "formik";
import { IBaseFieldProps } from "./base-field";
import { FieldWrapper } from "./field-wrapper";
import { validateTextAreaField } from "../../helpers/input";
import { StyledTextAreaField } from "./text-area-field.styles";
import { ThemeProvider } from "styled-components";
import { siteTheme } from "../../global/theme.styles";

export interface ITextFieldProps extends IBaseFieldProps {
}

export const TextAreaField:React.FC<ITextFieldProps> = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const textAreaFieldValidator = React.useCallback((value: string) => {
      return validateTextAreaField(value, props.required);
    }, [props.required]);
  
    const [field, meta] = useField({ name: props.name, validate: textAreaFieldValidator});

    return (
      <ThemeProvider theme={siteTheme.secondaryComponentTheme}>
      <StyledTextAreaField touched={meta.touched} filled={!!meta.value} error={!!meta.error}>
        <FieldWrapper {...props} label={label} touched={meta.touched} filled={!!meta.value} error={meta.error}>
          <Field {...field} {...props}
            as="textarea" 
            className="textarea-input" 
            validate={textAreaFieldValidator} 
          />
        </FieldWrapper>
      </StyledTextAreaField>
      </ThemeProvider>
    );
  };