import { createGlobalStyle } from 'styled-components/macro';
import {media} from './media.styles';
import { font } from './fonts.styles';
import { siteTheme } from './theme.styles';

export const GlobalStyles = createGlobalStyle`

  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html,
  body {
    font-size: ${font.size.normal};

    @media ${media.desktop} {
      font-size: ${font.size.primary};
    }

    @media (prefers-color-scheme: light) {
      background: ${siteTheme.primaryComponentTheme.backgroundColor.light};
      color: ${siteTheme.primaryComponentTheme.textColor.light};
    }

    @media (prefers-color-scheme: dark) {
      background: ${siteTheme.primaryComponentTheme.backgroundColor.dark};
      color: ${siteTheme.primaryComponentTheme.textColor.dark};
    }
  }

  body {
    margin: 0;
    overflow-anchor: none;
  }

  &.modal-open,
  &.nav-open {
    position: fixed;
    overflow-y: scroll;
  }

  .modal-open,
  .nav-open {
    width: 100%;
    overflow: hidden;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
  }

  canvas {
    display: inline-block;
    vertical-align: baseline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    font-style: normal;
  }

  strong,
  b {
    font-weight: normal;
    font-style: normal;

    em,
    i {
      font-weight: normal;
      font-style: normal;
    }
  }

  em,
  i {
    font-weight: normal;
    font-style: normal;

    strong,
    b {
      font-weight: normal;
      font-style: normal;
    }
  }

  a {
    background-color: transparent;

    img {
      border: 0;
    }
  }

  a:active,
  a:hover {
    outline: 0;
  }

  address {
    font-style: normal;
  }

  sub,
  sup {
    position: relative;
    vertical-align: baseline;
    line-height: 0;
    font-size: 75%;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25rem;
  }

  img,
  picture {
    display: block;
    margin: 0;
    max-width: 100%;
    height: auto;
  }

  iframe {
    display: block;
  }

  svg:not(:root) {
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    vertical-align: top;
  }

  hr {
    height: 0;
    box-sizing: border-box;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    color: inherit;
  }

  button {
    overflow: visible;
    border: 0;
    padding: 0;
    font-family: inherit;
    background: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type='button'],
  input[type='reset'],
  input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;

  &[type='checkbox'],
  &[type='radio'] {
    padding: 0;
    box-sizing: border-box;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  &[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: border-box;
  }

  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  }

  fieldset {
    border: 0;
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  legend {
    border: 0;
    padding: 0;
  }

  cite {
    font-style: normal;
  }

  textarea {
    overflow: auto;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;
