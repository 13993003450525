import styled from 'styled-components';
import { media } from '../../global/media.styles';
import { NoScrollBarStyle } from '../../global/placeholders';

export interface IStyledCarouselSlideProps {
    inModal?: boolean;
}

export const StyledCarouselSlide = styled.div<IStyledCarouselSlideProps>`
    ${NoScrollBarStyle}
    height: 100%;
    width: 100vw;
    padding-top: ${p =>  p.inModal ? '50px' : '0'};
    padding-bottom: ${p =>  p.inModal ? '80px' : '0'};
    overflow-y: scroll;
    counter-increment: slideCount;
    scroll-snap-align: center;
    grid-column-start: span 1;
    transform: translate3d(0, 0, 0);

    @media ${media.tablet} {
        padding-bottom: 0;
    }

    @media ${media.tabletLandscape} {
        width: 60vw;
    }

    @media ${media.desktopLarge} {
        padding-top: ${p =>  p.inModal ? '100px' : '0'};
    }

    .slide {
        &__content {

        }
    }
`;
