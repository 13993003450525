import styled from 'styled-components';
import { media } from '../global/media.styles';
import { facebookIconLightStyles, linkedinIconLightStyles, googleIconLightStyles } from '../global/icon.styles';
import { spacing } from '../global/spacing.styles';

export const StyledSocialNavigation = styled.div`
    width: 100%;
    padding-top: 10px;
    text-align: center;

    @media ${media.tablet} {
        text-align: right;
        padding-left: ${spacing.footerPaddingX}px;
    }

    .social-navigation {

        &__item {
            display: inline-block;
            width: 25px;
            height: 25px;
            margin: 0 10px;

            @media ${media.tablet} {
                margin-right: 0;
            }
        }
    }

    .facebook-icon,
    .linkedin-icon,
    .google-icon {
        width: 25px;
        height: 25px;
        transform: translate3d(0, 0, 0);
    }

    .facebook-icon {
        ${facebookIconLightStyles}
    }

    .linkedin-icon {
        ${linkedinIconLightStyles}
    }

    .google-icon {
        ${googleIconLightStyles}
    }
`;
 