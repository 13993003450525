/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { GatsbyBrowser } from 'gatsby';
import './src/global/webfonts.css';
import 'custom-event-polyfill';
import 'whatwg-fetch';

// You can delete this file if you're not using it
export const shouldUpdateScroll = props => {
    return false;
};

