import React from "react";
import ContactForm from "../forms/contact";

const ContactModal: React.FC = props => {
    const id = 'contact-modal';

    return (
        <div>
            <ContactForm />
        </div>
    );
}

export default ContactModal;
