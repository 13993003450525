import { useStaticQuery, graphql } from "gatsby";
import { SettingsQueryQuery } from "../../gatsby-graphql";

export const useSettings = () => {
    const data = useStaticQuery<SettingsQueryQuery>(graphql`
        query SettingsQuery {
            allWp {
                edges {
                  node {
                    safetyFirstPluginSettingsSettings {
                      safetyFirstApplyEmailCc
                      safetyFirstApplyEmailTo
                      safetyFirstContactEmailCc
                      safetyFirstContactEmailTo
                    }
                  }
                }
              }
        }`
    )

    return data.allWp.edges[0].node.safetyFirstPluginSettingsSettings;
}