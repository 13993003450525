import styled from 'styled-components';
import { smallParagraphTextStyles } from '../global/typography.styles';
import { blockSpacing } from '../global/spacing.styles';
import { font } from '../global/fonts.styles';

export const StyledImage = styled.figure`
    & figcaption {
        ${smallParagraphTextStyles}
        ${blockSpacing}
        font-family: ${font.secondary};
    }

    img {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    & .gatsby-image-wrapper {
        height: 100%;
        width: 100%;

        & > div {
            padding-top: 0 !important;
        }

        &--tall {
            &::after,
            img::after,
            .gatsby-image-wrapper__shim {
                padding-bottom: 110%;
            }
        }

        &--stout {
            &::after,
            img::after,
            .gatsby-image-wrapper__shim  {
                padding-bottom: 81.818181%;
            }
        }

        &--double {
            &::after,
            img::after,
            .gatsby-image-wrapper__shim  {
                padding-bottom: 50%;
            }
        }


        &--square {
            &::after,
            img::after,
            .gatsby-image-wrapper__shim  {
                padding-bottom: 100%;
            }
        }

        &--non-gatsby {
            position: relative;
            overflow: hidden;

            .non-gatsby-image {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
`;
