import React from "react";
import FooterNavigation from '../navigation/footer-navigation';
import { StyledFooter } from './footer.styles';
import SocialNavigation from "../navigation/social";
import { LinkPart } from "../parts/link";
import { getCurrentYear } from "../helpers/dates";

const Footer:React.FC = () => (
      <StyledFooter>
          <FooterNavigation/>
          <div className="footer__info">
            <address>
              <div className="footer__phone">
                <span className="footer__label">Phone:</span>
                <LinkPart to="tel:1-215-938-0508">(215) 938-0508</LinkPart>
              </div>
              <div className="footer__location">
                <span className="footer__label">Address:</span>
                <LinkPart to={"http://maps.apple.com/?address=85+Tomlinson+Rd+Unit+G,+Huntingdon+Valley,+PA+19006&t=h"}>
                  <span>85 Tomlinson Rd Unit G</span>
                  <span>Huntingdon Valley, PA 19006</span>
                </LinkPart>
              </div>
            </address>
            <SocialNavigation/>
          </div>
          <div className="footer__sub-navigation">
            <LinkPart to="/site-map">Site Map</LinkPart> | <LinkPart to="/privacy-policy">Privacy Policy</LinkPart>
          </div>
          <div className="footer__copyright">
            &copy; {getCurrentYear()} Safety First
          </div>
      </StyledFooter>
)

export default Footer