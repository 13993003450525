import * as React from 'react';
import parse, { attributesToProps, DOMNode, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { LinkPart } from './link';
import { StyledCMSContentPart } from './cms-content.styles';

export interface ICMSContentProps {
    content: string;
    options?: HTMLReactParserOptions;
}

const isParserElement = (domNode: DOMNode): domNode is Element => {
    const isTag = domNode.type === "tag";
    const hasAttributes = (domNode as Element).attribs !== undefined;
  
    return isTag && hasAttributes;
  };
  
export const defaultParserOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
  
      if (isParserElement(domNode)) {
  
        if (domNode.name === "a") {
          const props = attributesToProps(domNode.attribs);
  
          return <LinkPart to={props.href || ''} className={props.class || ''} cmsContent={true}>{domToReact(domNode.children, defaultParserOptions)}</LinkPart>
        }
      }
    }
};

export const CMSContentPart: React.FC<ICMSContentProps> = props => {

  return (
    <StyledCMSContentPart>
        {parse(props.content, props.options || defaultParserOptions)}
    </StyledCMSContentPart>
  );
};
