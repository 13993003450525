import styled from 'styled-components';
import { blur, colors, layer, opacity, themedBackground, themedColor, themedFill, themedLinks } from '../global/theme.styles';
import { font } from '../global/fonts.styles';
import { h3Styles } from '../global/typography.styles';
import { animation, transitionTiming } from '../global/animation.styles';
import { media } from '../global/media.styles';

export interface IStyledHeaderProps {
    scrolledUp?: boolean;
}

export const StyledHeader = styled.header<IStyledHeaderProps>`
    ${themedBackground};
    ${themedColor};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: ${p => p.scrolledUp ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)'};
    transition: transform ${animation.header};
    z-index: ${layer.header};
    backdrop-filter: ${`blur(${blur.base})`};

    @media ${media.tabletLandscape} {
        transform: translate3d(0, 0, 0);
    }

    html.modal-open & {
        transform: translate3d(0, 0, 0);
    }

    & a {
        ${themedLinks};
        text-decoration: none;
    }

    & .header {

        &__logo,
        &__ham-container {
            ${h3Styles}
            display: inline-block;
            width: 50%;
            font-size: ${font.size.title};
            padding: 20px;
        }

        &__ham {
            position: absolute;
            top: 30px;
            right: 20px;
            cursor: pointer;

            .nav-svg {
                ${themedFill};
                height: 30px;
                width: 30px;
            }


            &--close {
                & .ham__line {
                    width: 0;
                    transition-delay: none;
                }
                
                & .close__line {
                }
            }

            &--open {
                & .close__line {
                    height: 0;
                    transition-delay: 0ms;

                    &--two {
                        transition-delay: ${transitionTiming('navButton', 0.5)};
                    }
                }

                & .ham__line {
                    transition-delay: ${transitionTiming('navButton', 1)};

                    &--two {
                        transition-delay: ${transitionTiming('navButton', 1.333)};
                    }

                    &--three {
                        transition-delay: ${transitionTiming('navButton', 1.666)};
                    }
                }
            }
        }
    }

    & .svg__logo {
        height: 50px;
        width: 117px;
    }

    & .ham {
        &__line {
            transition: width ${animation.hamButtonLine};

            .header__ham--close & {
                width: 0;
            }

            &--two {
                transition-delay: ${transitionTiming('navButton', 0.333)};
            }

            &--three {
                transition-delay: ${transitionTiming('navButton', 0.666)};
            }
        }
    }

    & .close {
        
        &__line {
            transition: height ${animation.closeButtonLine} ${transitionTiming('navButton')};

            &--two {
                transition-delay: ${transitionTiming('navButton', 1.5)};
            }
        }
    }
`;
