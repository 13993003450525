import { useLocation } from "@reach/router";
import { useLinkContext } from "../parts/link.context";

export interface ILinkTypeProps {
    isPhone: boolean;
    isEmail: boolean;
    isRelative: boolean;
    isHash: boolean;
    isSameDomain: boolean;
    isExternal: boolean;
    isGatsby: boolean;
    isWordpressLink: boolean;
    to: string;
}

export const getLinkTypeProps = (url: string
): ILinkTypeProps => {
    const location = useLocation();
    const linkContext = useLinkContext();
    const wordpressRootUrl = linkContext.linkState?.wordpressRootUrl || '';
    url = url.replace(wordpressRootUrl, '');
    const isPhone = url.startsWith('tel:');
    const isEmail = url.startsWith('mailto:');
    const isRelative = url.startsWith('/');
    const isSameDomain = url.startsWith(location.origin);
    const isWordpressLink = url.startsWith(wordpressRootUrl);
    const isHash = url.startsWith('#') || url.includes(location.pathname + '#');
    const isExternal = !isSameDomain && !isRelative && !isEmail && !isHash && !isPhone && !isWordpressLink;
    const to = url;

    const urlProperties: ILinkTypeProps = {
        isGatsby: isRelative || isSameDomain,
        isEmail,
        isRelative,
        isSameDomain,
        isExternal,
        isHash,
        isPhone,
        isWordpressLink,
        to
    };

    return urlProperties;
}
