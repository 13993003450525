import styled from 'styled-components';
import { media } from '../global/media.styles';
import { spacing } from '../global/spacing.styles';

export const StyledFooterNavigation = styled.div`
    display: inline-block;
    width: 100%;
    grid-column: 1/3;
    text-align: center;

    @media ${media.tablet} {
        grid-column: 1/2;
        padding-left: ${spacing.footerPaddingX}px;
        text-align: left;
    }
`;
