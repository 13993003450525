import styled from 'styled-components';
import { animation } from '../../global/animation.styles';
import { colors, opacity, themedBackground, themedColor } from '../../global/theme.styles';
import { InputTextStyles, LabelTextStyles, specialtyStyles } from '../../global/typography.styles';

export interface IStyledFieldWrapperProps {
    touched?: boolean;
    filled?: boolean;
    error?: boolean;
}

export const StyledFieldWrapper = styled.div<IStyledFieldWrapperProps>`
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 10px;

    & input {
        ${themedColor}
        ${themedBackground}
        ${InputTextStyles}
        position: relative;
        width: 100%;
        text-align: center;
        outline: none;
        border: none;
        border-radius: 0px;
        color: ${p => p.error ? colors.red : ''};
        transition: color ${animation.hover}, background-color ${animation.hover};

        @media (prefers-color-scheme: light) {
            background-color: ${p => p.filled ? colors.white : ''};
        }

        @media (prefers-color-scheme: dark) {
            background-color: ${p => p.filled ? colors.black : ''};
        }

        &::placeholder {
            ${LabelTextStyles}
            opacity: ${opacity.none};
            transition: opacity ${animation.hover}, color ${animation.hover};

            @media (prefers-color-scheme: light) {
                color: ${p => p.error && p.touched ? colors.red : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.error && p.touched ? colors.red : colors.whitePartialOpacity};
            }
        }

        &:focus::placeholder,
        &:hover:focus::placeholder {
            opacity: ${p => p.filled ? opacity.full : opacity.medium};
        }

        &:focus {
            ${themedColor}
            ${themedBackground}

            & + .text-input__underline {
                transform: scaleX(1);
                opacity: ${opacity.medium};

                @media (prefers-color-scheme: light) {
                    background-color: ${p => p.error && p.touched ? colors.red : colors.black};
                }

                @media (prefers-color-scheme: dark) {
                    background-color: ${p => p.error && p.touched ? colors.red : colors.white};
                }
            }

            &:hover {
                ${themedColor}

                & + .text-input__underline {
                    opacity: ${p => p.filled ? opacity.none : opacity.medium};
                }
            }
        }

        &:hover {
            @media (prefers-color-scheme: light) {
                color: ${p => p.error && p.touched ? colors.red : colors.blackMediumOpacity};
            }

            @media (prefers-color-scheme: dark) {
                color: ${p => p.error && p.touched ? colors.red : colors.whiteMediumOpacity};
            }

            &::placeholder {
                opacity: ${opacity.partial};
            }

            & + .text-input__underline {
                transform: scaleX(1);
                opacity: ${opacity.medium};
            }
        }

        & + .text-input__underline {
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: inherit;
            transform: ${p => p.filled ? 'scaleX(1)' : 'scaleX(0)'};
            transform-origin: 50% 100%;
            transition: transform ${animation.hover}, background-color ${animation.hover}, opacity ${animation.hover};

            @media (prefers-color-scheme: light) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.black};
            }

            @media (prefers-color-scheme: dark) {
                background-color: ${p => p.error && p.touched ? colors.red : colors.white};
            }
        }

    }

    .error {
        ${specialtyStyles}
        padding: 5px 0px;
        color: ${colors.red};
        transform: ${p => p.touched && p.error ? 'scaleY(1)' : 'scaleY(0)'};
        transition: transform ${animation.inView};

        &::before {
            content: 'error';
            visibility: hidden;
            display: inline-block;
            width: 0px;
        }

        &__value {
            display: inline-block;

            &-exit {
                transition: opacity ${animation.inView};
                transition-duration: ${p => p.error ? '1ms' : ''};
                opacity: ${opacity.none};
                overflow-x: hidden;

                &-active {
                    opacity: ${opacity.full};
                    max-width: 100vw;
                    overflow-x: hidden;
                }
            }

            &-enter {
                opacity: ${opacity.full};
                max-width: 100vw; 
                transition: opacity ${animation.inView};

                &-active {
                    opacity: ${opacity.none};
                    overflow-x: hidden;
                }
            }
        }
    }
`;
