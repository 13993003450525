import React from 'react';
import { ImageGalleryBlockImagesFragmentFragment, Maybe } from '../../gatsby-graphql';
import { IGalleryBlockProps } from '../gutenberg/blocks/gallery';

export type IGalleryState = Partial<ReturnType<typeof useGalleryState>>;

export type IGalleryContext = IGalleryState;

export const GalleryContext = React.createContext<IGalleryContext | null>(
    null
);

export interface IGallery {
    id: number;
    component?: React.FC<IGalleryBlockProps>;
    images: Maybe<ImageGalleryBlockImagesFragmentFragment>[];
}


export type GalleryClickCallback = (target?: HTMLElement) => void;


export const useGalleryState = () => {
    const [galleryState, setGalleryState] = React.useState<IGallery[]>([]);

    const createGallery = React.useCallback(
        (images: Maybe<ImageGalleryBlockImagesFragmentFragment>[]) => {
            const newGallery: IGallery = {
                id: galleryState.length,
                images: images
            }
            setGalleryState(galleries => [...galleries, newGallery]);

            return newGallery.id;
        },
        [galleryState]
    );

    return {
        galleryState: galleryState,
        createGallery
    };
};


export const useGalleryContext = () => {
    const galleryContext = React.useContext(GalleryContext);

    if (!galleryContext) {
        throw new Error(
            'GalleryMeta Context used outside of GalleryMetaContext.Provider'
        );
    }

    return galleryContext;
};

export interface IGalleryProviderProps extends IGalleryState { };

export const GalleryContextProvider: React.FC<IGalleryProviderProps> = props => {
    const galleryContext = useGalleryState();

    return (
        <GalleryContext.Provider value={galleryContext}>
            {props.children}
        </GalleryContext.Provider>
    );
};
