import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { StyledFlyout } from "./flyout.styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MenuRenderer from "./menu-renderer";
import { useNavContext } from "../global/contexts/navigation.context";
import { FlyoutQueryQuery } from "../../gatsby-graphql";

const Flyout: React.FC = () => {
    const data: FlyoutQueryQuery = useStaticQuery(graphql`
      query FlyoutQuery {
          allWpMenuItem(filter: { locations: { eq: EXPANDED } } ) {
            nodes {
              ...MenuItemsFragment
            }
          }
      }`
    );
    const menuItems = data.allWpMenuItem?.nodes;
    const { isNavOpen } = useNavContext();

    const onEnterHandler = () => {
    // modalContext.onAnimationStateChange('enter');
    };

    const onEnteringHandler = () => {
    // modalContext.onAnimationStateChange('entering');
    };

    const onEnteredHandler = () => {
    // modalContext.onAnimationStateChange('entered');
    };

    const onExitHandler = () => {
    // modalContext.onAnimationStateChange('exit');
    };

    const onExitingHandler = () => {
    // modalContext.onAnimationStateChange('exiting');
    };

    const onExitedHandler = () => {
    // modalContext.onAnimationStateChange('exited');
    };

    return (
      <TransitionGroup>
        <CSSTransition
          timeout={350}
          onEnter={onEnterHandler}
          onEntering={onEnteringHandler}
          onEntered={onEnteredHandler}
          onExit={onExitHandler}
          onExiting={onExitingHandler}
          onExited={onExitedHandler}
        >
          <StyledFlyout isOpen={!!isNavOpen}>
              <div className="flyout__background" />
              <div className="flyout__content">
                {menuItems && 
                  <MenuRenderer menuItems={menuItems} />
                }
              </div>
          </StyledFlyout>
        </CSSTransition>
    </TransitionGroup>
    );
}

export default Flyout;
