import { css } from "styled-components";
import { media } from "./media.styles";

export const spacing = {
    pageTopPadding: 90,
    blockPaddingY: 10,
    blockPaddingYDesktop: 20,
    blockPaddingX: 15,
    footerPaddingX: 65,
    footerPaddingY: 25,
}

export const blockSpacing = css`
  padding: ${spacing.blockPaddingY}px ${spacing.blockPaddingX}px;

  @media ${media.tablet} {
      padding: ${spacing.blockPaddingYDesktop}px 5vw;
  }
`;