import React from 'react';
import { hasWindow } from '../../helpers/window';
import { scrollTop } from '../../helpers/positions';
import { throttle } from 'throttle-debounce';
import { useEventCallback } from '../../helpers/hooks';

const SCROLL_INTERVAL = 50;

export type IScrollState = Partial<ReturnType<typeof useScrollState>>;

export const ScrollContext = React.createContext<IScrollState | null>(
null
);

export interface ScrollState {
    currentPosition: number,
    prevPosition?: number,
}

const selectScrollState = (
    prevPosition?: number
  ): ScrollState | undefined =>
    hasWindow
      ? {
          currentPosition: scrollTop(),
          prevPosition,
        }
      : undefined;
  
export type ScrollCallback = (scroll?: number, lastScroll?: number) => void;
  
export const useScrollState = () => {
    const [scrollState, setScrollState] = React.useState(selectScrollState);
    const [scrollCallbacks, setScrollCallbacks] = React.useState<
      ScrollCallback[]
    >([]);
  
    const registerScrollCallback = React.useCallback(
      (scrollCallback: ScrollCallback) => {
        setScrollCallbacks([...scrollCallbacks, scrollCallback]);
      },
      [scrollCallbacks]
    );
  
    const unregisterScrollCallback = React.useCallback(
      (scrollCallback: ScrollCallback) => {
        setScrollCallbacks(
          scrollCallbacks.filter(callback => callback !== scrollCallback)
        );
      },
      [scrollCallbacks]
    );
  
    if (hasWindow) {
      const throttledSetScrollState = throttle(SCROLL_INTERVAL, setScrollState);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const handleScrollEvent = useEventCallback(() => {
        const newScrollState = selectScrollState(
          scrollState && scrollState.currentPosition
        );
  
        scrollCallbacks.map(scrollCallback =>
          scrollCallback(
            newScrollState && newScrollState.currentPosition,
            newScrollState &&
              newScrollState.prevPosition
          )
        );
  
        throttledSetScrollState(newScrollState);
      }, [scrollState && scrollState.currentPosition, scrollCallbacks]);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        window.addEventListener('scroll', handleScrollEvent);
  
        return () => {
          window.removeEventListener('scroll', handleScrollEvent);
        };
      }, [handleScrollEvent]);
    }

    return {
      scrollState: scrollState,
      registerScrollCallback,
      unregisterScrollCallback,
    };
};

export const useScrollContext = () => {
    const scrollContext = React.useContext(ScrollContext);

    if (!scrollContext) {
        throw new Error(
        'ScrollMeta Context used outside of ScrollMetaContext.Provider'
        );
    }

    return scrollContext;
};

export const ScrollContextProvider: React.FC = ({ children }) => {
    const scrollState = useScrollState();

    return (
        <ScrollContext.Provider value={scrollState}>
            {children}
        </ScrollContext.Provider>
    );
};
  