import { topPosition } from "./positions";
import { smoothScrollToY } from "./scroll";

export const hashNavigate = (hash: string) => {
    const scrollToElement = document.getElementById(hash);

    if ( scrollToElement ) {
      const headerOffset = 90;
      const scrollPosition = topPosition(scrollToElement as Element) - headerOffset;
      ;
      smoothScrollToY(scrollPosition);
    }
}