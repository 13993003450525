import styled from 'styled-components';
import { ButtonStyles } from '../global/placeholders';

export interface IStyledButtonProps {
    secondary?: boolean;
}

export const StyledButton = styled.button<IStyledButtonProps>`
    ${ButtonStyles}
`;

export const StyledLinkButton = styled.span<IStyledButtonProps>`
    ${ButtonStyles}
    display: inline-block;
`;
