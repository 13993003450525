import React from 'react';
import { useScrollContext } from './scroll.context';

export type IHeaderState = Partial<ReturnType<typeof useHeaderState>>;

export const HeaderContext = React.createContext<IHeaderState | null>(
null
);

export const useHeaderState = () => {
    const [scrolledUp, setScrolledUp] = React.useState(true);
    const { scrollState } = useScrollContext();

    const setHeaderVisible = React.useCallback(
        (isHeaderVisible: boolean) => {
            isHeaderVisible !== scrolledUp && setScrolledUp(isHeaderVisible);
        },
        [scrolledUp, setScrolledUp]
    );

    React.useEffect(() => {
        if (!!scrollState && !!scrollState.currentPosition && !!scrollState.prevPosition) {
            const scrollChange = scrollState.currentPosition - scrollState.prevPosition;

            if (Math.abs(scrollChange) > 1 || scrollState.currentPosition <= 0) {
                (scrollState.currentPosition <= 0 || scrollState.currentPosition <= scrollState.prevPosition) ? setHeaderVisible(true) : setHeaderVisible(false);
            }
        }
      }, [scrollState?.currentPosition, scrollState?.prevPosition, setScrolledUp]);

    return {
        scrolledUp,
        setHeaderVisible,
    };
};

export const useHeaderContext = () => {
    const headerContext = React.useContext(HeaderContext);

    if (!headerContext) {
        throw new Error(
        'HeaderMeta Context used outside of HeaderMetaContext.Provider'
        );
    }

    return headerContext;
};

export const HeaderContextProvider: React.FC = ({ children }) => {
    const headerState = useHeaderState();

    return (
        <HeaderContext.Provider value={headerState}>
            {children}
        </HeaderContext.Provider>
    );
};
  