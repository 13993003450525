import styled from 'styled-components';
import { LinkStyles } from '../global/typography.styles';
import { StyledLink } from './link.styles';

export interface IStyledCMSContentPartProps {
    secondary?: boolean;
}

export const StyledCMSContentPart = styled.span<IStyledCMSContentPartProps>`
    
    ${StyledLink} a {
        ${LinkStyles}
    }
`;
