import React, { useCallback } from 'react';

export type IFormState = Partial<ReturnType<typeof useFormState>>;

export type IFormContext = IFormState;

export const FormContext = React.createContext<IFormContext | null>(
null
);

export type FormState = 'Initial' | 'Submitting' | 'Failed' | 'Success';

export const getSubmitTextFromState = ( formState: FormState ) => {
    switch(formState) {
        case 'Initial':
            return 'Submit';
        case 'Failed': 
            return 'Failed';
        case 'Submitting':
            return 'Sending...';
        case 'Success':
            return 'Sent';
    }
}
  
export const useFormState = () => {
    const [formState, setFormState] = React.useState<FormState>('Initial');


    const updateFormState = useCallback(
        (newFormState: FormState) => {
            newFormState !== formState && setFormState(newFormState);
        },
        [formState, setFormState]
    );
  
    return {
      updateFormState,
      formState
    };
};


export const useFormContext = () => {
    const formContext = React.useContext(FormContext);

    if (!formContext) {
        throw new Error(
        'FormMeta Context used outside of FormMetaContext.Provider'
        );
    }

    return formContext;
};

export interface IFormProviderProps extends IFormState { };

export const FormContextProvider: React.FC<IFormProviderProps> = props => {
    const formContext = useFormState();

    return (
        <FormContext.Provider value={formContext}>
            {props.children}
        </FormContext.Provider>
    );
};
  