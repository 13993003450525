import React, { useCallback } from 'react';

export type ILinkState = Partial<ReturnType<typeof useLinkState>>;

export type ILinkContext = ILinkState;

export const LinkContext = React.createContext<ILinkContext | null>(
null
);

export interface LinkState {
    wordpressRootUrl?: string,
}


export type LinkClickCallback = (target?: HTMLElement) => void;

  
export const useLinkState = () => {
    const [linkState, setLinkState] = React.useState<LinkState>({});
    // const [linkClickCallbacks, setLinkClickCallbacks] = React.useState<
    //   LinkClickCallback[]
    // >([]);
  
    // const registerLinkClickCallback = React.useCallback(
    //   (linkClickCallback: LinkClickCallback) => {
    //     setLinkClickCallbacks([...linkClickCallbacks, linkClickCallback]);
    //   },
    //   [linkClickCallbacks]
    // );
  
    // const unregisterLinkClickCallback = React.useCallback(
    //   (linkClickCallback: LinkClickCallback) => {
    //     setLinkClickCallbacks(
    //       linkClickCallbacks.filter(callback => callback !== linkClickCallback)
    //     );
    //   },
    //   [linkClickCallbacks]
    // );
  
    // if (hasWindow) {
    //   // eslint-disable-next-line react-hooks/rules-of-hooks
    //   const handleLinkClickEvent = useEventCallback((event) => {
    //     const target = event.target;
    //     linkClickCallbacks.map(linkClickCallback =>
    //       linkClickCallback(target)
    //     );
  
    //   }, [linkClickCallbacks]);

    //   // eslint-disable-next-line react-hooks/rules-of-hooks
    //   React.useEffect(() => {
    //     window.addEventListener('click', handleLinkClickEvent);
  
    //     return () => {
    //       window.removeEventListener('click', handleLinkClickEvent);
    //     };
    //   }, [handleLinkClickEvent]);
    // }

    return {
      linkState: linkState,
      // registerLinkClickCallback,
      // unregisterLinkClickCallback,
    };
};


export const useLinkContext = () => {
    const linkContext = React.useContext(LinkContext);

    if (!linkContext) {
        throw new Error(
        'LinkMeta Context used outside of LinkMetaContext.Provider'
        );
    }

    return linkContext;
};

export interface ILinkProviderProps extends ILinkState { };

export const LinkContextProvider: React.FC<ILinkProviderProps> = props => {

    return (
        <LinkContext.Provider value={{...props}}>
            {props.children}
        </LinkContext.Provider>
    );
};
  