import { Formik } from "formik";
import React from "react";
import { trackEvent } from "../helpers/analytics";
import { IApplyFormState } from "./apply";
import { IContactFormState, formName as contactFormName } from "./contact";
import { useFormContext } from "./form.context";
import { StyledForm } from './form.styles';
import { submitFormData } from '../helpers/form';
import { useModalContext } from "../global/contexts/modal.context";

export type FormStates = IContactFormState | IApplyFormState;

export interface IFormProps {
  formName: string;
  formText?: string;
  successMessage?: string;
  initialValues: IContactFormState | IApplyFormState;
}

const FormWrapper: React.FC<IFormProps> = props => {
  const { formState, updateFormState } = useFormContext();
  const { isModalOpen } = useModalContext();

  React.useEffect(() => {
    if ((formState === 'Success' || formState === 'Failed') && !isModalOpen) {
      updateFormState && updateFormState('Initial');
    }
  }, [isModalOpen, formState, updateFormState]);

  return (
    <StyledForm isSuccess={formState === 'Success'}>
      <div className="form__content">
        <h3>{props.formName}</h3>
        <div className="form-text">{props.formText}</div>
        <Formik initialValues={props.initialValues}
          onSubmit={async values => {
            trackEvent('submit_lead', {
              event_category: 'leads',
              event_label: `Submit ${props.formName} Form`,
              value: `submit_${props.formName}`
            });
            updateFormState && updateFormState('Submitting');

            try {
              const formState = await submitFormData(values, props.formName);
              updateFormState && updateFormState(formState);
            } catch (err) {
              console.log(err);
              updateFormState && updateFormState('Failed');
            }
          }}
        >
          {props.children}
        </Formik>
      </div>
      <div className="form__success-message">
        {props.successMessage}
      </div>
    </StyledForm>
  )
}

export default FormWrapper;
