import React from "react";
import { IBaseFieldProps } from "./base-field";
import { validatePostalCodeField } from "../../helpers/input";
import { TextField } from "./text-field";

export interface IPostalCodeFieldProps extends IBaseFieldProps {
}

export const PostalCodeField:React.FC<IPostalCodeFieldProps> = (props) => {
  const postalCodeFieldValidator = React.useCallback((value: string) => {
    return validatePostalCodeField(value, props.required);
  }, [props.required]);

  return (
    <TextField {...props} validator={postalCodeFieldValidator} />
  );
};