import * as React from 'react';
import Img from 'gatsby-image';
import { StyledImage } from './image.styles';
import { graphql } from 'gatsby';
import { CMSContentPart } from './cms-content';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image";
import { FileSystemNode } from 'gatsby-source-filesystem';
import { FileNode, IGatsbyImageDataParent } from 'gatsby-plugin-image/dist/src/components/hooks';

// Image Ratios
// Default: whatever the fuck
// Stout: 6x5
// Tall: 10x11
// Double: 16: 8

export type ImageRatios = 'default' | 'stout' | 'tall' | "square" | 'double';

// We hava ll of theses dumb fragments bc there are no common attributes to query on
export const ImageFragment = graphql`
    fragment ImageFragment on WpMediaItem {
      sourceUrl
      mediaItemUrl
      src {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
`;

export const BlockCoverImageFragment = graphql`
    fragment BlockCoverImageFragment on WpCoreCoverBlockAttributes {
      url
      src {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
`;

export const BlockImageFragment = graphql`
    fragment BlockImageFragment on WpCoreImageBlockAttributes
    {
      url
      src {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
`;

export const BlockImageGalleryFragment = graphql`
    fragment BlockImageGalleryFragment on WpCoreGalleryBlockAttributesImages
    {
      url
      src {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
`;

export const BlockImageMediaTextFragment = graphql`
    fragment BlockImageMediaTextFragment on WpCoreMediaTextBlockAttributes
    {
      mediaUrl
      src {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
`;

export interface IImageProps {
  caption?: string;
  url?: string;
  ratio?: ImageRatios;
  height?: string;
  src?: FileSystemNode | FileNode | IGatsbyImageDataParent | IGatsbyImageData | undefined;
  alt?: string;
  priority?: "lazy" | "eager";
}

export const ImagePart: React.FC<IImageProps> = ({ caption, url, ratio, src, priority, height, alt }) => {
  const gatsbyImage = src ? getImage(src) : undefined;
  const imageRatio = ratio || 'default';
  const prefix = url && url.includes('http://') ? 'http://' : 'https://';
  let paddingBottom = "0";

  if (ratio !== 'default') {
    switch (ratio) {
      case 'double':
        paddingBottom = "50";
        break;
      case 'square':
        paddingBottom = "100";
        break;
      case 'tall':
        paddingBottom = "110";
        break;
      case 'stout':
        paddingBottom = "81.8181";
        break;
    }
  }

  return (
    <StyledImage>
      {!!gatsbyImage ?
        <GatsbyImage
          image={{ ...gatsbyImage }}
          alt={alt || ""}
          loading={priority ? priority : "lazy"}
          className={`gatsby-image-wrapper gatsby-image-wrapper--${ratio}`}
          style={{
            paddingBottom: `${paddingBottom}%`,
            height: height
          }}
        />
        :
        <div className={`gatsby-image-wrapper gatsby-image-wrapper--${ratio} gatsby-image-wrapper--non-gatsby`}><div className="gatsby-image-wrapper__shim" />
          <img
            className="non-gatsby-image"
            src={url ? url.replace('http://', prefix) : ''}
            alt={alt || ""}
          />
        </div>
      }
      {caption &&
        <figcaption><CMSContentPart content={caption} /></figcaption>
      }
    </StyledImage>
  );
};
