import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FooterQueryQuery } from "../../gatsby-graphql";
import { StyledFooterNavigation } from "./footer-navigation.styles"
import MenuRenderer from "./menu-renderer";

const FooterNavigation = () => {
  const data: FooterQueryQuery = useStaticQuery(graphql`
    query FooterQuery {
      allWpMenuItem(filter: { locations: { eq: FOOTER } } ) {
          nodes {
            ...MenuItemsFragment
          }
        }
    }`
  );
  const menuItems = data.allWpMenuItem?.nodes;

  return (
    <StyledFooterNavigation>
      {menuItems && 
        <MenuRenderer menuItems={menuItems} />
      }
    </StyledFooterNavigation>
  )
}

export default FooterNavigation;
