import styled from 'styled-components';
import { colors, layer, blur, themedBackground, themedColor } from '../global/theme.styles';
import { h3Styles, paragraphStyles } from '../global/typography.styles';
import { baseEasingFunction } from '../global/animation.styles';

export interface IStyledModalProps {
    isOpen: boolean,
    isMedia: boolean
}

export const StyledModal = styled.div<IStyledModalProps>`
    ${themedColor}
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: translate3d(0, 0, 0);
    z-index: ${layer.modal};
    overflow-y: hidden;
    pointer-events: ${p => p.isOpen ? 'all' : 'none'};
    backdrop-filter: ${p => p.isOpen ? `blur(${blur.base})` : 'blur(0)'};

    & .modal__background,
    & .modal__content {
        width: 100%;
        height: 100%;
        transition: opacity 350ms ${baseEasingFunction};
    }

    & .modal__background {
        ${themedBackground}
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background-color: ${p => p.isMedia ? colors.black : ''};
        opacity: ${p => p.isOpen ? (p.isMedia ? '0.7' : '1') : '0'};
        z-index: ${layer.modalBackground};
    }

    & .modal__content {
        ${themedBackground}
        /* position: fixed;
        top: ${p => p.isMedia ? 'calc(50% + 45px)' : '90px'};
        left: 0;
        height: calc(100% - 90px); */
        padding-top: 90px;
        /* transform: ${p => p.isMedia ? 'translate3d(0, -50%, 0)' : 'translate3d(0, 0, 0)' }; */
        background-color: ${p => p.isMedia ? 'transparent' : ''};
        opacity: ${p => p.isOpen ? '1' : '0'};
        overflow-y: auto;
        overflow-x: hidden;
        z-index: ${layer.modalContent};
    }

    & h3 {
        ${h3Styles}
    }

    & p {
        ${paragraphStyles}
    }
`;
