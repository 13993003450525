import React from "react";
import Footer from './footer';
import Header from "./header";
import Flyout from "../navigation/flyout";
import { StyledLayout } from "./layout.styles";
import { GlobalStyles } from "../global/global.styles";
import { PageTransition } from '../page-transition/page-transition';
import Modal from "../modals/modal";
import { LinkContextProvider } from "../parts/link.context";
import { graphql, useStaticQuery } from "gatsby";
import { GalleryContextProvider } from "../global/gallery.context";
import { ThemeProvider } from "styled-components";
import { siteTheme } from "../global/theme.styles";
import { ScrollContextProvider } from "../global/contexts/scroll.context";
import { HeaderContextProvider } from "../global/contexts/header.context";
import { ModalContextProvider } from "../global/contexts/modal.context";
import { NavContextProvider } from "../global/contexts/navigation.context";
import { ObserverContextProvider } from "../global/contexts/observer.context";
import { LayoutQuery } from "../../gatsby-graphql";

export interface ILayoutContext {
  setTheme: (theme: string) => void;
}

export const useLayoutTheme = () => {
  /**
   * On mount
   */

  React.useEffect(() => {
  }, []);
};

const Layout:React.FC = ({ children }) => {
  const theme = siteTheme;
const query = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      wp {
        generalSettings {
          url
        }
      }
    }
  `);
  
  return (
    <LinkContextProvider linkState={{wordpressRootUrl: query.wp?.generalSettings?.url || ''}}>
      <ThemeProvider theme={theme}>
        <StyledLayout>
          <GlobalStyles />
          <NavContextProvider>
            <ModalContextProvider>
              <ThemeProvider theme={theme.flyoutTheme}>
                <Flyout />
              </ThemeProvider>
              <GalleryContextProvider>
                <ThemeProvider theme={theme.modalTheme}>
                  <Modal />
                </ThemeProvider>
                <ScrollContextProvider>
                  <HeaderContextProvider>
                    <ThemeProvider theme={theme.headerTheme}>
                      <Header />
                    </ThemeProvider>
                    <ThemeProvider theme={theme.primaryComponentTheme}>
                      <PageTransition>
                        <ObserverContextProvider>
                          <main>{children}</main>
                        </ObserverContextProvider>
                      </PageTransition>
                    </ThemeProvider>
                  </HeaderContextProvider>
                </ScrollContextProvider>
              </GalleryContextProvider>
            </ModalContextProvider>
          </NavContextProvider>
          <ThemeProvider theme={theme.footerTheme}>
            <Footer />
          </ThemeProvider>
        </StyledLayout>
      </ThemeProvider>
    </LinkContextProvider>
  )
}

export default Layout;
